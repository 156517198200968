<template>
  <div>
    <div class="banner2" style="height: 100vh; width: 100%; position: fixed; top: 0">
      <img src="@/assets/banner 1.jpg" alt="" />
    </div>
    <div class="title-text">
      <!-- 轻松赚钱是一种生活方式 -->
      <p class="title">{{ $t('home.head') }}</p>
      <p>{{ $t('home.p2') }}</p>
      <p class="text">{{ $t('home.p3') }}</p>
      <div class="scanCode">
        <div class="log-box">
          <!-- <a href="https://play.google.com/store/apps/details?id=com.mxcome.app.mxcome"> -->
          <img @click="onJump(1)" style="cursor: pointer" src="@/assets/mxcomeImg/13158.png" alt="" />
          <!-- </a> -->
          <img @click="onJump(2)" style="cursor: pointer" src="@/assets/mxcomeImg/14079.png" alt="" />
        </div>
        <div class="link"></div>
        <div class="scanCode-box">
          <img src="@/assets/20231223-193239.jpg" />
          <span class="tips">{{ $t('home.downInfo') }}</span>
        </div>
      </div>
      <img class="img-box" src="@/assets/giftbanner.png" alt="gift" />
    </div>
    <div :class="show ? 'conter' : 'conters'">
      <ActivityInfo v-show="show" @onShow="onShow" />
    </div>
  </div>
</template>
<script>
import ActivityInfo from './activityInfo.vue'
export default {
  components: {
    ActivityInfo
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    onShow(val) {
      this.show = val == false ? val : true
      console.log(this.show)
    },
    onJump(val) {
      switch (val) {
        case 1:
          window.open('https://play.google.com/store/apps/details?id=com.mxcome.app.mxcome')
          break
        case 2:
          window.open('https://apps.apple.com/us/app/mxcome/id6446675692')
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="less" scoped>
.banner2 {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 100%;
  }
  .content {
    margin-top: -19%;
  }
}
.title-text {
  height: 100%;
  position: relative;
  padding: 193px 182px 0;
  text-align: left;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  .title {
    margin-bottom: 22px;
    text-align: left;
    font-size: 48px;
    font-weight: 600;
  }
  .text {
    margin-top: 5px;
  }
  .img-box {
    position: absolute;
    top: 193px;
    right: 180px;
    height: 497px;
    width: 477px;
  }
  .scanCode {
    width: 341px;
    display: flex;
    justify-content: space-between;
    margin-top: 105px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    padding: 24px 32px 28px;
    .log-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        height: 34px;
      }
      img:nth-child(2) {
        margin-top: 18px;
      }
    }
    .link {
      border-left: 1px solid #f5f5f5;
    }
    .scanCode-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        height: 78px;
        width: 78px;
      }
      span {
        color: #292929;
        font-size: 12px;
      }
    }
  }
}
.conter {
  position: relative;
  margin: 100px 181px 0;
  width: 80%;
  height: 810px;
  overflow: hidden;
  margin-bottom: 100px;
  border-radius: 20px;
  background: var(--white-100, #fff);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
}

.conters {
  max-height: 60vh;
}
</style>
