<template>
  <div class="navTop">
    <div class="navContent">
      <div class="img-item">
        <img alt="logo" @click="$router.push({ name: 'home' })" src="../assets/logo.png" />
        <span class="right">
          <!-- 越玩越赚 -->
          <div style="display: flex; flex-direction: column">
            <span class="slogan">{{ $t('nav.play') }}</span>
            <span class="slogan">{{ $t('nav.play2') }}</span>
          </div>
          &nbsp;｜
          <span class="login-button" @click="login">
            <img class="left" alt="IP" src="../assets/IP.png" /> {{ $t('nav.login')
            }}<img class="ipImg" alt="IP" src="../assets/open.png" />
          </span>
        </span>
      </div>
      <div class="nav-item">
        <el-menu :default-active="activated" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item v-for="(item, index) in navList" :key="index" :index="item.value">
            {{ $t(item.labelName) }}
          </el-menu-item>
        </el-menu>
        <div style="position: relative; display: flex; align-items: center">
          <img style="width: 18px; height: 18px; margin-top: -4px" alt="earth" src="../assets/earth.png" />
          <el-select v-model="lang" ref="select" placeholder="请选择" @change="changeLangEvent">
            <el-option v-for="item in langList" :key="item.value" :label="item.label" :value="item.value">
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right; margin-left: 8px"><img :src="item.logoSrc" style="width: 23px" /></span>
            </el-option>
          </el-select>
          <div class="flag">{{ country }} &nbsp;<img :src="flagSrc" style="width: 23px" /></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import defaultOPtions from '@/vendor/defaultData.js'
export default {
  data() {
    return {
      activated: 'home',
      navList: defaultOPtions.navList,
      langList: defaultOPtions.langList,
      lang: localStorage.getItem('lang') || 'thai',
      flagSrc: '',
      country: ''
    }
  },
  created() {
    // localStorage.setItem('lang', 'thai')
    this.$store.commit('setLang', 'thai')
    console.log(this.$store)
  },
  watch: {
    $route: {
      handler(value) {
        this.activated = value ? value.name : 'home'
        if (value.name === 'newsDetails') this.activated = 'newsCenter'
      },
      immediate: true,
      deep: true
    },
    lang: {
      handler(val) {
        if (val) {
          this.langList.forEach((item) => {
            if (val === item.value) {
              this.flagSrc = item.logoSrc
              this.country = item.label
            }
          })
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleSelect(key) {
      // console.log(key, keyPath)
      this.activated = key
      this.$router.push({ name: key })
    },
    changeLangEvent(value) {
      const that = this
      that.$i18n.locale = value || 'thai'
      this.$store.commit('setLang', value)
      localStorage.setItem('lang', value)
    },
    login() {
      window.open('https://shop.mxcome.com/#/login', '_blank')
    }
  }
}
</script>
<style lang="less" scoped>
.navTop {
  padding: 0 50px;
  width: 100%;
  position: fixed;
  top: 30px;
  z-index: 99;
  font-weight: 500;
  .navContent {
    width: 100%;
    background: #fff;
    border-radius: 50px;
    display: flex;
    padding: 15px 35px;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  }
  .img-item {
    display: flex;
    align-items: center;
    .right {
      display: flex;
      align-items: center;
      margin-left: 15px;
      font-size: 20px;
      color: #f45656;
      font-weight: 700;
      .slogan {
        max-width: 130px;
        display: inline-block;
        text-align: left;
        line-height: 17px;
        font-size: 16px;
        font-weight: 400;
      }
      .login-button {
        cursor: pointer;
        background: #f45656;
        border-radius: 50px;
        margin-left: 10px;
        font-size: 15px;
        color: #ffffff;
        padding: 8px 22px;
        img {
          margin-left: 8px;
          width: 16px;
          margin-top: -2px;
        }
        .left {
          margin-left: 0;
          width: 20px;
        }
      }
    }
  }
  .el-menu.el-menu--horizontal {
    border: none;
    margin-right: 30px;
  }
  img {
    width: 180px;
  }
  .smallImg {
    width: 60px;
    margin-right: 10px;
  }
  .nav-item {
    display: flex;
    align-items: center;
    /deep/.el-input--suffix {
      z-index: 2;
      .el-input__inner {
        border: none;
        padding-right: 0px;
        width: 136px;
        padding-left: 5px;
        opacity: 0;
      }
    }
  }
  .flag {
    position: absolute;
    top: 0;
    z-index: 1;
    background: #fff;
    font-size: 16px;
    color: #6d6d6d;
    margin-left: 30px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  /deep/.el-menu--horizontal {
    .el-menu-item {
      height: 45px;
      line-height: 45px;
      color: #000000;
      font-weight: 500;
    }
    .el-menu-item.is-active {
      color: #f45656;
      border-color: #f45656;
    }
  }
}
</style>
