<!-- 
  官网聊天功能组件
  neme hlz
  2023/10/9 
 -->
<template>
  <div class="chat-box">
    <iframe :src="src" frameborder="0" style="width: 300px; height: 500px"></iframe>
  </div>
</template>
<script>
export default {
  name: 'chatS',
  data() {
    return {
      src: 'https://admin.mxcome.com/ssss.html'
    }
  },
  methods: {}
}
</script>
<style scoped>
.chat-box {
  position: fixed;
  bottom: 8px;
  right: 20px;
  /* background-color: #555; */
  z-index: 0;
}
</style>
