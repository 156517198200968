export default {
  messageA: 'Under development, please wait', // 正在开发中,敬请期待!
  Uploading: 'Uploading...', // 上传中
  uploadMessage: 'Please upload required fields!', // 请上传必填项
  address: '30 Soi Ramintra 32 Tha Raeng Subdistrict, Bang Khen District, Bangkok 10220', // 公司地址
  submitted_Successfully: 'Submitted', // 提交成功
  reproduction_of_articles: 'Reproduction of articles', // 文章转载
  content_citation: 'Content citation', // 内容引用
  official_customer_service: 'Official customer service', // 官方客服
  Re_upload: 'Re-upload', // 重新上传
  The_photo_format_is_incorrect: 'The photo format is incorrect', // 照片格式有误
  Photo_size_exceeds_10M: 'Photo size exceeds 10M', // 照片大小超过10M
  Please_upload_the_resume_attachment: 'Please upload the resume attachment!', // 请上传简历附件！
  menus: {
    activity: 'Exciting Activity', //精彩活动
    news: 'News Center', //新闻中心
    aboutUs: 'About Us', //关于我们
    partenship: 'Platform Cooperation', //平台合作
    joinUs: 'Join Us' //加入我们
  },
  nav: {
    play: 'MORE PLAY', // 越玩越赚
    play2: 'MORE EARN',
    login: 'Operation login', // 运营登录
    centerTitle: 'MXCOME, Welcome to the MXCOME world', // 欢迎移民淘金世界
    centerTetx: 'Brand Self-Operated Co-creating The Future' // 品牌自营 共创未来
  },
  bottom: {
    innovation: 'Innovation', //创新
    transform: 'changes', //改变
    future: 'the future', //未来
    Copyright: 'MXCOME © Copyright 2023',
    Copyright_authorization_application: 'Copyright authorization application', // 版权授权申请
    Please_select_the_authorization_type: 'Please select the authorization type', // 请选择授权类型
    Authorization_Type: 'Authorization Type', // 授权类型

    Please_briefly_describe_your_needs: 'Please briefly describe your needs' // 请简单描述您的需求
  },
  home: {
    head: 'Making money easily is a way of life.', //轻松赚钱是一种生活方式
    p2: 'New online shopping, social networking and content scenes are waiting for you to join!', //全新的网购、社交、内容创作场景等你来玩
    p3: 'Stimulating creativity and making money are simple and efficient.', //我们专注提升用户价值，让内容变现简单高效
    head2: 'Enter a new era of brand self-management', //进入“你即品牌”时代
    p4: 'Reconstruct the e-commerce scene. Brand self-management is a channel change!', //持续赋能品牌价值！
    p5: 'Sharing is justice, continuously empowering brand growth.', //构建「真·品牌自营」的全新商业生态
    endTime: 'remaining time', //剩余时间
    deadline: 'cut-off time', //截止时间
    scan: 'Please scan the code to join activities', //请扫码参与活动
    quickly: 'Become MXCOMERS in 30 sec.', //30S淘金达人速成
    checkpoint: 'Level', //关卡
    countZero: 'Count down', //倒计时
    day: 'Day', //天
    hours: 'Hour', //时
    minute: 'minute', //分
    second: 'second', //秒
    top1000: 'The top 1,000 MXCOMERS will receive the following ranking awards for free', //前1000名淘金达人将免费获得以下排名大奖
    search: 'search activities and prizes', //查询活动及中奖
    Subscription: 'Gold Rush Marketing Subscription',
    emailAddress: 'Please fill in E-mail address', //请输入邮箱地址
    subscribe: 'subscribe', //订阅
    message: 'We will issue vouchers and brand activities notices from time to time', //我们将不定期发放优惠券及品牌活动通知
    downInfo: 'Scan Code',
    NoActivity: 'No activity at the moment!',
    superWednesday: 'Super Wednesday', // 超级星期三
    harderRigth: 'Explore surprises in the app', // 进入APP探索惊喜
    eventReservation: 'activity reservation', // 活动预约
    recommendationRewards: 'referral reward', // 推荐奖励
    deal: 'transaction', // 成交
    one: 'order', // 单
    latestEntry: 'latest entry' // 最新入驻
  },
  // 品牌合作页面
  partenship: {
    aboutSettlingIn: 'about entry', // 关于入驻
    quickEntry: 'fast entry', // 快速入驻
    read: '5-minute read', // 5分钟阅读
    read2: '10 minutes to complete', // 10分钟阅读
    applyingForResidency: 'apply for entry', // 申请入驻
    platformReview: 'platform review', // 平台审核
    activateStore: 'Activate shop', // 激活店铺
    Brand_Investment_Handbook: 'Brand Investment Handbook', // 品牌招商手册
    Please_choose_the_language_that_suits_you: 'Please choose the language that suits you', // 请选择您适合的语言
    applyNow: 'Apply now', // 立即申请
    contactInformation: 'Contact information', // 联络信息
    brandMessage: 'Brand info', // 品牌信息
    fullName: 'Name', // 姓名
    contactName: "Contact person's name", // 联系人姓名
    Brand: 'Brand name', // 品牌名称
    enterContactPhoneNumber: 'Enter contact number', // 输入联系电话
    brandCategories: 'Brand category', // 品牌类目
    email: 'E-mail', // E-mail
    relatedWebsites: 'Related websites', // 相关网站
    addLink: 'Add link', // 新增链接
    Data: 'Related materials', // 相关资料
    prompt:
      'If the information is complete, you can click "Continue Certification", and the platform will complete the brand operation review within 48 hours', // 如资料完全，可点击“继续认证”，平台将于48小时内完成品牌运营审核
    prompt1:
      'If you need to prepare materials, you can log in to the operation backend after receiving the key and supplement the authentication materials when activating the store', // 如需准备资料，可在收到密钥后登陆运营后台，并在激活店铺时补充认证材料
    prompt2: 'Log in to the brand operation backend with a key, click to activate the store, complete store settings, and start operating', // 通过密钥登陆品牌运营后台，点击激活店铺，完成店铺设置即可开始运营
    nextStep: 'Next step', // 下一步
    pleaseSelect: 'please choose', // 请选择
    brandBrochure: 'Brand brochure (PDF)', // 品牌宣传册（PDF）
    appleProductIntroduction: 'Introduction to Apple products', // Apple产品介绍
    established: 'Established', // 已设立
    establishedText: 'Local operating company', // 本地运营公司
    notEstablished: 'Not established', // 未设立
    prompt3: 'Once the operating company is established, the store can be activated directly to start operations.', // 已设立运营公司可以直接激活店铺开展运营
    prompt4: 'Without establishing an operating company, you can complete the establishment process with the help of the platform.', // 未设立运营公司可以通过平台帮助完成设立
    submitForReview: 'Submit for review', // 提交审核
    ApplicationForEntry: 'Entry application successfully submitted" in Chinese is', // 入驻申请已成功提交
    ApplicationForEntry1: 'Approval and key distribution will be completed within 48 hours.', // 48 小时内完成审批及密钥发放
    ApplicationForEntry2: 'Receive the audit reminder', // 接收审核提醒
    ApplicationForEntry3: 'Receive the key email', // 接收密钥邮件
    additionalInformation: 'upplementary information has been successfully submitted.', // 补充资料已成功提交
    prompt5: 'Upon completion of selection, you can use the key to log in to the store and complete the authentication.', // 如选择完成，可用密钥登陆店铺后补充认证
    prompt6: 'Should the information be complete, you can proceed with the authentication, saving time on the review process.', // 如资料完全，您可继续认证，节约审核时间
    prompt7: 'Please ensure smooth communication, and if necessary, we will follow up', //  请确保通讯通畅，如有必要我们将会回访
    prompt8: 'The platform will complete the review within 48 hours. Please pay attention to SMS and emails', // 平台将于48小时内完成审核，请注意短信及邮件
    prompt9: 'Please provide accurate information, otherwise it will affect the application result', // 请提供准确信息，否则将影响申请结果
    complete: 'complete', // '完成'
    continuingCertification: 'Continuing certification', // 继续认证
    Login_to_email_to_view_key: 'Login to email to view key', // 登陆邮箱查看密钥
    brandInformation: 'Brand information', //品牌资料
    brandSide: 'Brand', // 品牌方
    jointVenture: 'Joint ventures', // 合资公司
    nationalAgency: 'Country agent', // 国家代理
    trademarkApplicationForm: 'Certification of Trademark Registration', // 商标申请书
    trademarkCertificate: 'Trademark certificate', // 商标证书
    certificateOfIncorporation: '(Brand) Trademark Certificate of Authorisation', // (品牌)商标授权书
    registrationCertificateS: 'Company registration certificate number', // 公司登记证号
    text2: 'Please upload relevant materials according to the actual situation',
    text3: 'You can also activate the store and supplement the certification', // 请根据实际情况上传相关材料，您也可激活店铺后补充认证
    authenticationInformation: 'Supplementary certification', // 补充认证
    companyInformationS: 'Company information', // 公司资料
    directorSsignature: 'Directors signature', // 董事签署
    authorizedSignature: 'Authorized signature', // 授权签署
    Example: 'Example', //
    registrationCertificate: 'Corporate registration certificate', // 公司登记证书
    affidavit: 'Company affidavit', // 公司宣誓书
    effectiveDate: 'Effective deadline', // '有效截止日期'
    effectiveDateS: 'Please choose the deadline', // '请选择截止日期'
    Enter: 'Please enter', // '请输入'
    text8: 'The brand name cannot be repeated, please re-enter', // 品牌名称不能重复，请重新输入
    text6: 'Single image size not more than 3MB, support jpg, png, pdf, bmp, jpeg, heic format', // 单张图片大小不超过3MB，支持jpg、png、bmp格式
    text7: 'Single file size not more than 10MB, support pdf format', // 单个文件不能超过10MB,支持pdf
    taxAdministration: 'Tax registration Certificate', // '税务登记证
    taxAdministrationS: 'Tax registration certificate number', // '税务登记证号'
    text4: "The company's certification documents need to be signed and stamped by a director or authorized representative", // '公司认证资料需董事或授权代表签字盖章'
    directorEmpower: 'Director s Authorization', // 董事授权书
    directorEmpowerTitle: 'Name of Director, Name of Authorized Representative', // 董事名字、授权代表名字
    continuouslyIncreasing: 'Continuously increasing...', // 持续增加中...
    text9: 'Sorry! Could not find the corresponding email login address. Please log in to your email to check your email!', // 抱歉!未找到对应的邮箱登录地址，请自己登录邮箱查看邮件！
    text10: 'The company certification materials need to be signed and stamped by the director or authorized representative' // 公司认证资料需董事或授权代表签字盖章
  },

  aboutUss: {
    Gold_Rushing_Expert: 'MXWINNER', // 淘金达人
    Touching_Users: 'Touching Users', // 触达用户
    title_text: 'Breaking through brand', // 突破品牌销售屏障
    title_text2: 'sales barriers', //
    title_text1: 'Help users easily make money', // 帮助用户轻松赚钱
    title_text3: 'asily',
    scheduleAVisit: 'Schedule a visit', // 预约来访
    reservation: 'reservation', // 预约
    suggestedFeedback: 'Suggested feedback', // 建议反馈
    contactName: 'Contact Name', // 联系人姓名
    suggestTypesA: 'Suggest Types', // 建议类型
    suggestTypes: 'Please select the suggestion type', // 请选择建议类型
    suggestedContent: 'Please enter the suggested content', // 请输入建议内容
    Pictures: 'Pictures', // 上传图片
    text1: 'We listen attentively', // 我们用心倾听
    text2: 'If you express your ideas and the platform adopts them, you have a chance to receive a maximum reward of 1000 gold coins', // 发表领的想法，一但平台采纳，有机会获得最高1000金币奖励
    submitTo: 'submit to', // 提交
    visitingTimeA: 'Visiting time', // 来访时间
    visitingTime: 'Please select the visit time', // 请选择来访时间
    visitingInformation: 'Please enter visit information', // 请输入来访信息
    text3: 'Tuesday, Wednesday, and Thursday', // 周二 三 四
    functionalSuggestions: 'Functional suggestions', // 功能建议
    interactiveSuggestions: 'Interactive suggestions', // 交互建议
    bugSubmission: 'Bug submission', // bug提交
    other: 'Others' // 其他
  },
  // 新闻中心
  newsCenter: {
    OfficialUpdates: 'Official updates', // 官方动态
    mediaCoverage: 'media coverage', // 媒体报道
    publicWelfare: 'public welfare', // 社会公益
    keywords: 'Enter news key', // 输入新闻关键
    Popular: 'Popular' // 热门
  },
  // 加入我们
  joinUs: {
    experience: 'experience', // 经验
    years_or_more: 'Years or more', // 年以上
    high_school: 'high school', // 高中
    junior_college: 'junior college', // 大专
    undergraduate_course: 'undergraduate course', // 本科
    postgraduate: 'postgraduate', // 研究生
    Dr: 'Dr', // 博士
    job_search: 'Job search', // 岗位查询
    recruitment_Department: 'Recruitment Department', // 招聘部门
    text1: 'We know you enjoy holidays, and there is a holiday notice in the bottom left corner', // 我们知道你喜欢放假，左下角有放假通知
    nature_of_Work: 'Nature of Work', // 工作性质
    full_time: 'full-time', // 全职
    part_time_job: 'sideline', // 兼职
    practice: 'practice', // 实习
    job_Notice: 'Job Notice', // 求职须知
    submit_resume: 'Submit resume', // 投递简历
    area: 'area', // 所在地区
    please_select_your_region: 'Please select your region', // 请选择所在地区
    Upload_attached_resume: 'Upload attached resume', // 上传附件简历
    Internet_celebrity_joining: 'Internet celebrity joining', // 网红加入
    screenshot_of_Today_s_Channel: "Screenshot of Today's Channel", // 当日频道截图
    view_examples: 'View examples', // 查看示例
    text4: 'Only 10MB in size, file format supported (JPG PNG)', // 仅限10MB大小，文件格式支持（JPG PNG）
    text5: 'We welcome KOLs to join and jointly build a high-quality content ecosystem', // 我们欢迎KOL的加入，共建优质内容生态
    text6: 'Minimum joining standard, fan count must exceed 10K', // 最低加入标准，粉丝数量需超过 10K
    text7: 'Please upload according to the actual situation and keep your phone unobstructed', // 请根据实际情况上传，并保持手机通畅
    recruitment_policy: 'recruitment policy', // 招聘政策
    Onboarding_process: 'Onboarding process', // 入职流程
    Professional_red_line: 'Professional red line', // 职业红线
    Not_yet_released_please_stay_tuned: 'Not yet released, please stay tuned', // 暂未发布敬请期待
    text8: 'Only 10MB in size, file format supported (PDF WORD)', // 仅限10MB大小，文件格式支持（PDF WORD）
    upload_attached_resume: 'Upload attached resume' // 上传附件简历
  },
  // 底部导航栏
  navigationBottom: {
    event_application_portal: 'Event application portal', // 活动申请入口
    apply_for: 'apply for', // 申请
    cooperation: 'Cooperation', // 战略合作
    platform_ecological_partners: 'Platform ecological partners', // 平台生态伙伴
    official_channel: 'Official channel', // 官方频道
    discover_more_exciting_things: 'Discover more exciting things', // 发现更多精彩
    company_Navigation: 'Company Navigation', // 公司导航
    copyright_authorization: 'Copyright authorization', // 版权授权
    media_Only: 'Media Only', // 仅限传媒
    holiday_calendar: 'Holiday calendar', // 放假日历
    click_to_view: 'Click to view', // 点击查看
    this_month_s_holiday: "This month's holiday", // 本月放假日
    today: 'today', // 今日
    mark_as_vacation: 'Mark as vacation', // 标记为放假
    some_public_holidays_have_adjustments: 'Some public holidays have adjustments', // 部分公众假日存在调整
    return_to_Today: 'Return to Today' // 返回今日
  },
  news: {
    newsCenter: 'News Center', //新闻中心
    MNews: 'MXCOME News',
    official: 'Directly hitting the market dynamics, MXCOME official authority releases', //直击市场动态，MXCOME官方权威发布
    productNews: 'Product News', //产品消息
    dynamic: 'Operational trends', //运营动态
    report: 'Media report', //媒体报道
    statement: 'official statement', //官方声明
    search: 'Search news history', //搜索历史新闻动态
    readOn: 'Please continue to read' //请继续阅读
  },
  aboutUs: {
    brochure: 'BROCHURE', //手册
    Value: 'Value Co-Creation', //价值经济
    Win: 'Win-Win', //共赢
    economics: 'Value Co-Creation, Mutual Benefit and Win-win', //'价值经济 互利共赢',
    highTech: 'We are committed to becoming a great social and technology company with sustainable development.', //'我们致力成为可持续发展的社会型伟大科技企业',
    web: 'Focus on sharing economy driven by Web3, digitalization and artificial intelligence as core technologies', //'专注于Web3、数字化、人工智能为核心技术驱动的分享经济商业生态',
    profit: 'Profits mainly come from platform services and advertising', //'主要利润来自平台服务及广告',
    flagship: `MXCOME-The world's first Web3 social recommendation platform (commodity friend content service)`, //MXCOME-全球首家Web3社交推荐平台  (商品   好友   内容   服务)
    mega: 'Mega content production, XEFR infinite possibility ; Come to the smart Eco of TAO', //'超级内容生产，传递无限可能，进入道的智慧生态'
    infinitePossibilities: '', //'超级内容生产，传递无限可能',
    zoology: 'Come to the smart Eco of TAO', //'进入道的智慧生态',
    Spirit: 'The Spirit of MXCOME', //'MXCOME的精神'
    soul: 'Soul of gold rush', //'淘金之魂',
    message: "Founder's Message", //'创始寄语',
    messageTip: 'Adhere to justice and innovation, revere nature, and enjoy life', //'正义创新 敬畏自然 热爱生活',
    values: 'Values', //'价值观',
    valuesTip: 'Respect users and originality', //'尊重用户 尊重原创',
    valuesTip1: 'Above brands', //'以上品牌',
    vision: 'Prospect', //'愿景',
    visionTip: 'Build a new business order', //'构建全新商业秩序',
    mission: 'Mission', //'使命',
    missionTip: 'Let everyone make money easily', //'让人人都轻松赚钱',
    contactUs: 'Contact with us', //'与我们取得联系',
    contact: 'contact', //'联系',
    ReceiveTime: 'Reception time: Monday to Friday 9:00-18:00 (closed on Saturdays, Sundays and public holidays)', //'接待时间：9:00-18:00(周六、周日及公众假期休息)',
    Mailbox: 'Mailbox', //'总务邮箱',
    harken: 'Listen with our heart', //'我们用心倾听',
    proposal: 'We listen attentively to the ideas of product, design, platform rules, or features you expect.', //'无论是产品、设计、还是平台规则，或是你期待的功能，发表你的想法。',
    award: 'If the effective suggestions are adopted, you will have a chance to get 500 to 5000 gold coins for gold rush.', //'有效建议采纳将有机会获得500-5000淘金金币奖励',
    configSuggest: 'Suggest now', //'提交建议',
    office: 'Office Address', //'我们的办公室',
    address: 'Soi Ramintra 32 Intersection 6Tha Raeng Subdistrict, Bang Khen District, Bangkok 10220',
    attention: 'Follow us', //'关注我们',
    moreContent: 'More content', //'更多精彩内容',
    userSuggest: 'Your suggestions', //'用户建议',
    name: 'Name', //'姓名',
    region: 'Region', //'区域',
    thai: 'Thailand', //'泰国',
    china: 'China', //'中国',
    ID: 'MXCOME ID',
    phone: 'Contact number', //'联系电话',
    suggestContent: 'Please write down your suggestion in words.', //'请以文字描述建议内容',
    upload: 'Upload attachments', //'上传附件',
    uploadTips: '10MB size only, (PDF WORD) File formats are allowed', //'仅限10MB大小，文件格式支持（PDF WORD）',
    submit: 'Submit', //'提交'
    GoHere: 'go here',
    copySuccess: 'copy succeeded',
    copy_link: 'copy link' //复制链接
  },
  join: {
    joinM: 'Join MXCOME',
    welcome: 'Welcome to join the MXCOME family',
    btnThai: 'Thailand',
    btnCh: 'china',
    changeWork: 'Want to change a job? MXCOME sincerely invites you to join us.', //想换工作？MXCOME诚邀您加入。
    joinUs: 'Join us', //加入我们
    whichWork: 'What new job do you want?', //想换什么工作？
    JobTitle: 'Please enter the position name', //请输入职位名称
    addressDetail: 'Address', //详细地址
    workplacePlaceholder: 'Please select a city office', //请选择城市办公室
    workplace: 'work place', //工作地点
    thai: 'Thailand', //泰国
    china: 'China', //中国
    usa: 'United States', //美国
    BangkokStudio: 'Bangkok studio', //曼谷工作室
    ChangshaStudio: 'Changsha studio', //长沙工作室
    ShenzhenStudio: 'Shenzhen studio', //深圳工作室
    required: 'Skills and Qualifications Requirements', //技能与资格要求
    openPositions: 'open positions',
    controlCenter: 'A.control center',
    ProductCenter: 'T.product center',
    businessCenter: 'B.business center',
    department: 'department',
    design: 'design',
    research: 'research and development',
    assetManagement: 'asset management',
    hr: 'hr',
    generalOffice: 'general office', //General Office
    planning: 'planning',
    operate: 'operate',
    choose: 'Please choose', //请选择
    type: 'Job type', //职位类型
    FullTime: 'Full-time', //全职
    partTime: 'Part-time', //兼职
    practice: 'Internship', //实习
    recruitmentPolicies: 'Recruitment policy', //招聘政策
    procedure: 'Induction process', //入职流程
    redLine: 'Prohibition of occupation', //职业红线
    markAsHoliday: 'mark as holiday', //标记为放假日
    somePublicDaysHaveAdjust: 'Some public days have adjustments', //部分公众日存在调整
    holidayCalendar: 'Holiday calendar'
  },
  collaboration: {
    invite: 'Sincerely welcome to and join in our MXCOME World', //诚邀移民MXCOME的世界
    together: 'Strictly abide by the contract, cooperate sincerely to benefit the world, and create a better future together',
    multi: 'Multidimensional cooperation', //多维合作
    thai: 'Thailand', //泰国
    china: 'China', //中国
    interaction: 'activity cooperation', //活动合作
    platformOnboard: 'platform onboarding', //平台入驻
    events: 'Host a brand event', //承办品牌专场
    information: 'Deliver cooperative information', //投递合作资料
    circular: 'Official review notice', //官方审核通告
    only: '10MB size only, (PDF WORD EXCEL) File formats are allowed', //仅限10MB大小，文件格式支持（PDF WORD EXCEL)
    search: 'Search brand name', //搜索品牌名称
    category: 'Category', //类别
    brand: 'Brand', //品牌
    LOGO: 'LOGO',
    deliveryTime: 'Delivery time', //投递时间
    result: 'Application result', //申请结果
    remark: 'Remark', //备注
    pass: 'Pass', //通过
    reject: 'Reject',
    SONY: 'SONY Thailand', //SONY泰国
    // 平台合作
    TypeCooperation: 'Cooperation type', //合作类型
    brand: 'Brand', //品牌
    CheckInTime: 'Time of entry', //入驻时间
    OfficialAuditCircular: 'Official review time', //官方审核通告
    BrandSettlement: 'Brand Entry', //品牌入驻
    BrandHome: 'Country of Brand', //品牌归属地
    BrandName: 'Brand name', //品牌名称
    ContactName: 'Contact name', //联系人名称
    AreaCode: 'Area code', //区号
    ContactNumber: 'Phone number', //联系电话
    emailBrand: 'E-mail', //邮箱
    webBrand: 'Brand website', //品牌官网
    SubmitApplication: 'Submit application', //提交申请
    YourApplicationPleaseWaitGoodNews: 'Your application has been submitted, please wait for notifications', //您的申请已提交，请静候佳音
    PleaseKeepPhoneOpen: 'Please keep your phone on, we will call you if necessary.', //请保存电话畅通，如有必要我们将向您致电
    PleaseKeepPhoneOpen2: 'We will complete the review within 48 hours, and send you messages via SMS and email.', //我们将于48小时内完成审核，并通过短信及邮件方式发送
    know: 'Got it' //知道了
  }
}
