export default {
  messageA: 'อยู่ระหว่างการพัฒนา โปรดรอติดตามความคืบหน้า', // 正在开发中,敬请期待期待!
  Uploading: 'อัพโหลด...', // 上传中
  uploadMessage: 'กรุณาอัพโหลดรายการที่จำเป็น!', // 请上传必填项
  address: '30 ซอย รามอินทรา 32 แขวง ท่าแร้ง เขต บางเขน กรุงเทพมหานคร 10220', // 公司地址
  submitted_Successfully: 'ส่งสำเร็จ', // 提交成功
  reproduction_of_articles: 'บทความพิมพ์ซ้ำ', // 文章转载
  content_citation: 'การอ้างอิงเนื้อหา', // 内容引用
  official_customer_service: 'ฝ่ายบริการลูกค้าอย่างเป็นทางการ', // 官方客服
  Re_upload: 'อัพโหลดใหม่', // 重新上传
  The_photo_format_is_incorrect: 'รูปแบบภาพถ่ายผิดพลาด', // 照片格式有误
  Photo_size_exceeds_10M: 'ขนาดภาพมากกว่า 10 เมตร', // 照片大小超过10M
  Please_upload_the_resume_attachment: 'กรุณาอัปโหลดไฟล์แนบ CV!', // 请上传简历附件！
  menus: {
    activity: 'บอร์ดกิจกรรม',
    news: 'ศูนย์รวมข่าวสาร',
    aboutUs: 'เกี่ยวกับเรา',
    partenship: 'ร่วมมือกันระหว่างแพลตฟอร์ม',
    joinUs: 'เข้าร่วมกับเรา'
  },
  nav: {
    play: 'ยิ่งใช้ยิ่งได้',
    play2: '',
    login: 'ลงชื่อเข้าใช้',
    centerTitle: 'ยินดีต้อนรับเข้าสู่โลกของ', // 欢迎移民淘金世界
    centerTetx: 'ดำเนินการแบรนด์ด้วยตนเอง สร้างอนาคตร่วมกัน' // 品牌自营 共创未来
  },
  bottom: {
    innovation: 'นวัตกรรม',
    transform: 'เปลี่ยนแปลง',
    future: 'อนาคต',
    Copyright: 'MXCOME © ลิขสิทธิ์ 2023',
    Copyright_authorization_application: 'การขออนุญาตลิขสิทธิ์', // 版权授权申请
    Please_select_the_authorization_type: 'กรุณาเลือกประเภทการอนุมัติ', // 请选择授权类型
    Authorization_Type: 'ประเภทการอนุญาต', // 授权类型
    Please_briefly_describe_your_needs: 'กรุณาอธิบายความต้องการของคุณ' // 请简单描述您的需求
  },
  home: {
    head: 'หาเงินแบบง่ายๆก็เป็นไลฟ์สไตล์อย่างหนึ่ง',
    p2: 'มีทั้งช้อปออนไลน์ ได้เข้าสังคม และสร้างคอนเทนต์อีกมากมายรอให้คุณได้ร่วมสนุก!',
    p3: 'ปลุกไอเดียใหม่ๆ และเปลี่ยนคอนเทนต์เป็นเงินได้ง่ายๆ',
    head2: 'ก้าวเข้าสู่ยุคของการจัดการแบรนด์ด้วยตนเอง',
    p4: 'ปรับโครงสร้างตลาดออนไลน์ และจัดการแบรนด์ด้วยตนเองเพื่อไปสู่ช่องทางใหม่!',
    p5: 'การแชร์คือคำตอบที่จะช่วยให้แบรนด์เติบโตอย่างต่อเนื่อง',
    endTime: 'เหลือเวลาอีก',
    deadline: 'วันและเวลาที่จะปิดรับ',
    scan: 'กรุณาแสกนเพื่อเข้าร่วมกิจกรรม',
    quickly: 'วิธีเป็นMXCOMER ใน 30 วินาที',
    checkpoint: 'ระดับ',
    countZero: 'นับถอยหลัง',
    day: 'วัน',
    hours: 'ชั่วโมง',
    minute: 'นาที',
    second: 'วินาที',
    top1000: 'MXCOMER 1000 รายชื่อแรกจะได้รับฟรีรางวัลอันดับดังนี้',
    search: 'ค้นหากิจกรรมและลุ้นรับรางวัล',
    Subscription: 'ร่อนทอง สมัครสมาชิกการตลาด',
    emailAddress: 'กรุณาใส่ที่อยู่อีเมล์',
    subscribe: 'สมัคร',
    message: 'เราจะส่งคูปองและแจ้งเตือนกิจกรรมเป็นครั้งคราว',
    downInfo: 'ดาวน์โหลดรหัส',
    NoActivity: 'ไม่มีการทำงาน!',
    superWednesday: 'ซูเปอร์ พุธ', // 超级星期三
    harderRigth: 'ดาวน์โหลดแอปฯเพื่อเจอกับสิ่งที่น่าตื่นตาตื่นใจ', // 进入APP探索惊喜
    eventReservation: 'นัดหมายจัดอีเวนต์', // 活动预约
    recommendationRewards: 'รางวัลการแนะนำ', // 推荐奖励
    deal: 'ปิดการขาย', // 成交
    one: 'รายการ', // 单
    latestEntry: 'การเข้าพักล่าสุด' // 最新入驻
  },

  // 品牌合作页面
  partenship: {
    Gold_Rushing_Expert: 'MXWINNER', // 淘金达人
    Touching_Users: 'เข้าถึงผู้ใช้', // 触达用户
    title_text: 'ทลายกำแพงการขายของแบรนด์', // 突破品牌销售屏障
    title_text1: 'ช่วยให้ผู้ใช้มีรายได้ง่ายยิ่งขึ้น', // 帮助用户轻松赚钱
    aboutSettlingIn: 'เกี่ยวกับการตั้งถิ่นฐาน', // 关于入驻
    quickEntry: 'บริการด่วน', // 快速入驻
    read: '5 นาทีอ่าน', // 5分钟阅读
    read2: '10 นาที', // 10分钟阅读
    applyingForResidency: 'สมัครเข้าเรียน', // 申请入驻
    platformReview: 'แพลตฟอร์มตรวจสอบ', // 平台审核
    activateStore: 'เปิดร้านค้า', // 激活店铺
    Brand_Investment_Handbook: 'คู่มือการสร้างแบรนด์', // 品牌招商手册
    Please_choose_the_language_that_suits_you: 'กรุณาเลือกภาษาที่เหมาะสม', // 请选择您适合的语言
    applyNow: 'ส่งคำขอทันที', // 立即申请
    contactInformation: 'ข้อมูลติดต่อ', // 联络信息
    brandMessage: 'ข้อมูลแบรนด์', // 品牌信息
    fullName: 'ชื่อ', // 姓名
    contactName: 'ชื่อ-สกุลผู้ติดต่อ', // 联系人姓名
    Brand: 'ชื่อแบรนด์', // 品牌名称
    enterContactPhoneNumber: 'กรอกเบอร์ติดต่อ', // 输入联系电话
    brandCategories: 'ประเภทสินค้า', // 品牌类目
    email: 'อีเมล', // E-mail
    relatedWebsites: 'เว็บไซต์', // 相关网站
    addLink: 'เพิ่มลิงก์', // 新增链接
    Data: 'เอกสารที่เกี่ยวข้อง', // 相关资料
    prompt: 'หากข้อมูลครบถ้วนสามารถคลิก "ดำเนินการรับรอง" และแพลตฟอร์มจะเสร็จสิ้นการตรวจสอบการดำเนินงานของแบรนด์ภายใน 48 ชั่วโมง', // 如资料完全，可点击“继续认证”，平台将于48小时内完成品牌运营审核
    prompt1:
      'สำหรับการเตรียมข้อมูลสามารถเชื่อมโยงไปถึง back office การดำเนินงานหลังจากได้รับกุญแจและวัสดุรับรองเพิ่มเติมเมื่อเปิดใช้งานร้านค้า', // 如需准备资料，可在收到密钥后登陆运营后台，并在激活店铺时补充认证材料
    prompt2: 'ดำเนินการพื้นหลังด้วยคีย์เชื่อมโยงไปถึงแบรนด์คลิกเพื่อเปิดใช้งานร้านค้าเสร็จสิ้นการตั้งค่าร้านค้าเพื่อเริ่มต้นการดำเนินงาน', // 通过密钥登陆品牌运营后台，点击激活店铺，完成店铺设置即可开始运营
    nextStep: 'ต่อไป', // 下一步
    pleaseSelect: 'กรุณาเลือก', // 请选择
    brandBrochure: 'โบว์ชัวร์แบรนด์ (PDF)', // 品牌宣传册（PDF）
    appleProductIntroduction: 'Apple产品介绍', // Apple产品介绍
    established: 'จดทะเบียนแล้ว', // 已设立
    establishedText: 'บริษัทดำเนินการในประเทศ', // 本地运营公司
    notEstablished: 'ยังไม่จดทะเบียน', // 未设立
    prompt3: 'บริษัทที่จดทะเบียนแล้ว สามารถเปิดใช้งานร้านค้าได้ทันที', // 已设立运营公司可以直接激活店铺开展运营
    prompt4: 'บริษัทที่ยังไม่จดทะเบียน สามารถติดต่อแพลตฟอร์มให้ช่วยเหลือได้', // 未设立运营公司可以通过平台帮助完成设立
    submitForReview: 'ส่งคำขอ', // 提交审核
    ApplicationForEntry: 'ส่งคำขอเข้าร่วมสำเร็จ', // 入驻申请已成功提交
    ApplicationForEntry1: 'ใช้เวลาตรวจสอบและส่งไอดีรหัสผ่านภายใน 48 ชั่วโมง', // 48 小时内完成审批及密钥发放
    ApplicationForEntry2: 'ช่องทางการรับแจ้งเตือน', // 接收审核提醒
    ApplicationForEntry3: 'อีเมลสำหรับรับไอดีและรหัสผ่าน', // 接收密钥邮件
    additionalInformation: 'ส่งข้อมูลเสริมเรียบร้อยแล้ว', // 补充资料已成功提交
    prompt5: 'หากการเลือกเสร็จสิ้นการรับรองเพิ่มเติมหลังจากลงจอดที่ร้านค้าด้วยกุญแจที่มีอยู่', // 如选择完成，可用密钥登陆店铺后补充认证
    prompt6: 'หากข้อมูลครบถ้วนคุณสามารถดำเนินการรับรองและประหยัดเวลาในการตรวจสอบ', // 如资料完全，您可继续认证，节约审核时间
    prompt7: 'โปรดตรวจสอบให้แน่ใจว่าการสื่อสารเป็นไปอย่างราบรื่นและเราจะกลับไปเยี่ยมหากจำเป็น', //  请确保通讯通畅，如有必要我们将会回访
    prompt8: 'แพลตฟอร์มจะเสร็จสิ้นการตรวจสอบภายใน 48 ชั่วโมงโปรดทราบ SMS และ Mail', // 平台将于48小时内完成审核，请注意短信及邮件
    prompt9: 'โปรดให้ข้อมูลที่ถูกต้องมิฉะนั้นจะมีผลต่อผลการสมัคร', // 请提供准确信息，否则将影响申请结果
    complete: 'เสร็จสิ้น', // '完成'
    continuingCertification: 'ยื่นเอกสาร', // 继续认证
    Login_to_email_to_view_key: 'เชื่อมโยงไปถึงกล่องจดหมายเพื่อดูคีย์', // 登陆邮箱查看密钥
    brandInformation: 'ข้อมูลแบรนด์', //品牌资料
    brandSide: 'ฝ่ายแบรนด์', // 品牌方
    jointVenture: 'กิจการร่วมค้า', // 合资公司
    nationalAgency: 'ตัวแทนจำหน่ายต่างประเทศ', // 国家代理
    trademarkApplicationForm: 'หนังสือรับรองการจดทะเบียนเครื่องหมายการค้า', // 商标申请书
    trademarkCertificate: 'ใบทะเบียนเครื่องหมายการค้า', // 商标证书
    certificateOfIncorporation: '(แบรนด์)หนังสืออนุญาตให้ใช้เครื่องหมายการค้า', // (品牌)商标授权书
    registrationCertificateS: 'เลขที่ใบจดทะเบียนบริษัท', // 公司登记证号
    text2: 'กรุณาอัปโหลดเอกสารตามความเป็นจริง',
    text3: 'คุณสามารถเพิ่มเติมเอกสารได้หลังจากเปิดใช้งานร้านค้า', // 请根据实际情况上传相关材料，您也可激活店铺后补充认证
    authenticationInformation: 'ส่งเอกสาร', // 补充认证
    companyInformationS: 'ข้อมูลบริษัท', // 公司资料
    directorSsignature: 'กรรการลงนาม', // 董事签署
    authorizedSignature: 'ผู้รับมอบลงนาม', // 授权签署
    Example: 'ดูตัวอย่าง', //
    registrationCertificate: 'ใบจดทะเบียนบริษัท', // 公司登记证书
    affidavit: 'หนังสือรับรองบริษัท', // 公司宣誓书
    effectiveDate: 'ใช้ได้ถึงวันที่', // '有效截止日期'
    effectiveDateS: 'กรุณาเลือกวันที่', // '请选择截止日期'
    Enter: 'กรุณากรอก', // '请输入'
    taxAdministration: 'ใบจดทะเบียนภาษีมูลค่าเพิ่ม (ภพ.20)', // '税务登记证
    taxAdministrationS: 'เลขที่ใบจดทะเบียนภาษีมูลค่าเพิ่ม', // '税务登记证号'
    text8: 'ชื่อแบรนด์ไม่สามารถซ้ำได้ กรุณากรอกใหม่อีกครั้ง', // 品牌名称不能重复，请重新输入
    text6: 'ขนาดรูปภาพไม่เกิน 3MB รองรับไฟล์ .jpg .png .jpeg .heic .pdf และ .bmp', // 单张图片大小不超过3MB，支持jpg、png、bmp格式
    text7: 'ขนาดไฟล์ไม่เกิน 10MB รองรับไฟล์ PDF', // 单个文件不能超过10MB,支持pdf
    text4: 'เอกสารของนิติบุคคลจะต้องลงนามชื่อกรรมการผู้มีอำนาจหรือผู้รับมอบอำนาจพร้อมประทับตราบริษัท', // '公司认证资料需董事或授权代表签字盖章'
    directorEmpower: 'หนังสือมอบอำนาจ', // 董事授权书
    directorEmpowerTitle: 'ชื่อกรรมการ หรือผู้รับมอบอำนาจ', // 董事名字、授权代表名字
    continuouslyIncreasing: 'กำลังอัปเดตแบรนด์ที่เข้าร่วมใหม่...', // 持续增加中...
    text9: 'ขอโทษ! ไม่พบที่อยู่เข้าสู่ระบบกล่องจดหมายที่ตรงกัน กรุณาเข้าสู่ระบบกล่องจดหมายเพื่อดูข้อความด้วยตัวคุณเอง!', // 抱歉!未找到对应的邮箱登录地址，请自己登录邮箱查看邮件！
    text10: 'หนังสือรับรองบริษัท ต้องลงนามโดยกรรมการหรือผู้แทนผู้มีอำนาจลงนามและประทับตรา' // 公司认证资料需董事或授权代表签字盖章
  },
  aboutUss: {
    Gold_Rushing_Expert: 'MXWINNER', // 淘金达人
    Touching_Users: 'เข้าถึงผู้ใช้', // 触达用户
    title_text: 'ทลายกำแพงการขายของแบรนด์', // 突破品牌销售屏障
    title_text2: '', //
    title_text1: 'ช่วยให้ผู้ใช้มีรายได้ง่ายยิ่งขึ้น', // 帮助用户轻松赚钱
    title_text3: '',
    scheduleAVisit: 'นัดหมายเข้าพบ', // 预约来访
    reservation: 'นัดหมาย', // 预约
    suggestedFeedback: 'เสนอความคิดเห็น', // 建议反馈
    contactName: 'ชื่อ-สกุลผู้ติดต่อ', // 联系人姓名
    suggestTypesA: 'ประเภทที่แนะนำ', // 建议类型
    suggestTypes: 'กรุณาเลือกประเภทการแนะนำ', // 请选择建议类型
    suggestedContent: 'กรุณากรอกเนื้อหา', // 请输入建议内容
    Pictures: 'อัปโหลดรูป', // 上传图片
    text1: 'เรายินดีรับฟัง', // 我们用心倾听
    text2: 'หากข้อเสนอแนะของท่านเป็นประโยชน์กับเรา ท่านมีโอกาสได้รับรางวัลสูงสุด 1,000 เหรียญ', // 发表领的想法，一但平台采纳，有机会获得最高1000金币奖励
    submitTo: 'ส่ง', // 提交
    visitingTimeA: 'เวลาเข้าชม', // 来访时间
    visitingTime: 'เลือกเวลาที่ต้องการนัดหมาย', // 请选择来访时间
    visitingInformation: 'กรอกข้อมูลการนัดหมาย', // 请输入来访信息
    text3: 'อังคาร พุธ พฤหัสบดี', // 周二 三 四
    functionalSuggestions: 'คำแนะนำการทำงาน', // 功能建议
    interactiveSuggestions: 'คำแนะนำการโต้ตอบ', // 交互建议
    bugSubmission: 'ส่งข้อบกพร่อง', // bug提交
    other: 'อื่นๆ' // 其他
  },
  // 新闻中心
  newsCenter: {
    OfficialUpdates: 'ความเคลื่อนไหว', // 官方动态
    mediaCoverage: 'รายงานข่าวสาร', // 媒体报道
    publicWelfare: 'บำเพ็ญประโยชน์', // 社会公益
    keywords: 'กรอกคีย์เวิร์ดข่าว', // 输入新闻关键
    Popular: 'กำลังเป็นกระแส' // 热门
  },
  // 加入我们
  joinUs: {
    experience: 'ประสบการณ์', // 经验
    years_or_more: 'มากกว่า ปี', // 年以上
    high_school: 'โรงเรียนมัธยม', // 高中
    junior_college: 'วิทยาลัยและมหาวิทยาลัย', // 大专
    undergraduate_course: 'มหาวิทยาลัย', // 本科
    postgraduate: 'นักศึกษาระดับบัณฑิตศึกษา', // 研究生
    Dr: 'หมอ', // 博士
    job_search: 'โพสต์สอบถาม', // 岗位查询
    recruitment_Department: 'แผนกจัดหางาน', // 招聘部门
    text1: 'เรารู้ว่าคุณชอบวันหยุดและประกาศวันหยุดที่มุมซ้ายล่าง', // 我们知道你喜欢放假，左下角有放假通知
    nature_of_Work: 'ลักษณะการทำงาน', // 工作性质
    full_time: 'เต็มเวลา', // 全职
    part_time_job: 'นอกเวลา', // 兼职
    practice: 'การฝึกงาน', // 实习
    job_Notice: 'เคล็ดลับการหางาน', // 求职须知
    submit_resume: 'ส่ง CV', // 投递简历
    area: 'ภูมิภาค', // 所在地区
    please_select_your_region: 'กรุณาเลือกภูมิภาค', // 请选择所在地区
    Upload_attached_resume: 'อัปโหลดไฟล์แนบ CV', // 上传附件简历
    Internet_celebrity_joining: 'เน็ตไอดอลเข้าร่วม', // 网红加入
    screenshot_of_Today_s_Channel: 'ภาพหน้าจอของช่องวัน', // 当日频道截图
    view_examples: 'ดูตัวอย่าง', // 查看示例
    text4: 'ขนาด 10MB เท่านั้นรองรับรูปแบบไฟล์ (JPG PNG)', // 仅限10MB大小，文件格式支持（JPG PNG）
    text5: 'เรายินดีต้อนรับการเข้าร่วมของ KOL เพื่อสร้างนิเวศวิทยาของเนื้อหาที่มีคุณภาพร่วมกัน', // 我们欢迎KOL的加入，共建优质内容生态
    text6: 'เกณฑ์ขั้นต่ำในการเข้าร่วมและจำนวนผู้ติดตามต้องมีมากกว่า 10K', // 最低加入标准，粉丝数量需超过 10K
    text7: 'กรุณาอัปโหลดตามสถานการณ์จริงและทำให้โทรศัพท์ของคุณราบรื่น', // 请根据实际情况上传，并保持手机通畅
    recruitment_policy: 'นโยบายการจ้างงาน', // 招聘政策
    Onboarding_process: 'กระบวนการปฐมนิเทศ', // 入职流程
    Professional_red_line: 'สายสีแดงอาชีพ', // 职业红线
    Not_yet_released_please_stay_tuned: 'ยังไม่ได้รับการปล่อยตัวในขณะนี้โปรดติดตาม ...', // 暂未发布敬请期待
    text8: 'ขนาด 10MB เท่านั้นรองรับรูปแบบไฟล์ (PDF WORD)', // 仅限10MB大小，文件格式支持（PDF WORD）
    upload_attached_resume: 'อัปโหลดไฟล์แนบ CV' // 上传附件简历
  },
  // 底部导航栏
  navigationBottom: {
    event_application_portal: 'ส่งคำขออีเวนต์', // 活动申请入口
    apply_for: 'ส่งคำขอ', // 申请
    cooperation: 'ความร่วมมือเชิงกลยุทธ์', // 战略合作
    platform_ecological_partners: 'พันธมิตรแพลตฟอร์ม', // 平台生态伙伴
    official_channel: 'ช่องทางอื่นๆของเรา', // 官方频道
    discover_more_exciting_things: 'พบกับสิ่งที่น่าสนใจอีกมากมาย', // 发现更多精彩
    company_Navigation: 'ไปยังบริษัทของเรา', // 公司导航
    copyright_authorization: 'การอนุญาตลิขสิทธิ์', // 版权授权
    media_Only: 'สำหรับสื่อ', // 仅限传媒
    holiday_calendar: 'ปฏิทินวันหยุด', // 放假日历
    click_to_view: 'คลิกเพื่อดู', // 点击查看
    this_month_s_holiday: 'วันหยุดของเดือน', // 本月放假日
    today: 'วันนี้', // 今日
    mark_as_vacation: 'ทำเครื่องหมายเป็นวันหยุด', // 标记为放假
    some_public_holidays_have_adjustments: 'มีการปรับเปลี่ยนวันหยุดราชการบางส่วน', // 部分公众假日存在调整
    return_to_Today: 'กลับไปวันนี้' // 返回今日
  },
  news: {
    newsCenter: 'ศูนย์รวมข่าวสาร',
    MNews: 'MAXCOME NEWS',
    official: 'MXCOME เปิดตัวพร้อมตีตลาดอย่างเป็นทางการ',
    productNews: 'เนื้อหาเกี่ยวกับสินค้า',
    dynamic: 'แนวทางธุรกิจ',
    report: 'ข่าวสาร',
    statement: 'ประกาศ',
    search: 'ค้นหาประวัติข่าวสาร',
    readOn: 'อ่านข่าวต่อได้ที่นี่'
  },
  aboutUs: {
    brochure: 'ง่าย ๆ',
    Value: 'Value Co-Creation',
    Win: 'Win-Win',
    economics: 'การสร้างคุณค่าร่วมกัน ผลประโยชน์ร่วมกัน และ win-win', //价值经济 互利共赢
    highTech: 'เรามุ่งมั่นจะเป็นองค์กรเทคโนโลยีเพื่อสังคมที่ยอดเยี่ยมด้วยการพัฒนาอย่างยั่งยืน', //我们致力成为可持续发展的社会型伟大科技企业
    web: 'ระบบนิเวศธุรกิจทางเศรษฐกิจแบบแบ่งปันมุ่งเน้นไปที่การขับเคลื่อนโดย Web3.0 การแปลงข้อมูลไปสู่รูปแบบดิจิทัลและปัญญาประดิษฐ์เป็นหลัก', //专注于Web3、数字化、人工智能为核心技术驱动的分享经济商业生态
    profit: 'กำไรมีที่มาจากการบริการบนแพลตฟอร์มและสื่อโฆษณา', //主要利润来自平台服务及广告
    flagship: 'MXCOME-แพลตฟอร์มแนะนำโซเชียล Web3 แห่งแรกของโลก (บริการเนื้อหาเพื่อนสินค้าโภคภัณฑ์)', //商业旗舰-MXCOME 全球首家web3社交推荐平台
    mega: 'การผลิตเนื้อหาคุณภาพ ถ่ายทอดอย่างไร้ข้อจำกัด/เข้าสู่วิถีการอยู่ร่วมกันอย่างชาญฉลาด', //Mega content production, XEFR infinite possibility ; Come to smart Eco of TAO
    infinitePossibilities: '', //超级内容生产，传递无限可能
    zoology: 'ไปสู่วิถีการอยู่ร่วมกันอย่างชาญฉลาด', //进入道的智慧生态
    Spirit: 'The Spirit of MXCOME',
    soul: 'เอกลักษณ์ของ MXCOME', //淘金之魂
    message: 'วัฒนธรรมองค์กร', //创始寄语
    messageTip: 'นวัตกรรมเพื่อความยุติธรรม รักษ์ธรรมชาติ สนุกไปกับการใช้ชีวิต', //正义创新 敬畏自然 热爱生活
    values: 'คุณค่า', //价值观
    valuesTip: 'เคารพผู้ใช้และต้นแบบ', //尊重用户 尊重原创
    valuesTip1: 'แบรนด์ข้างต้น', //尊重用户 尊重原创
    vision: 'วิสัยทัศน์', //愿景
    visionTip: 'สร้างระเบียบทางธุรกิจรูปแบบใหม่', //构建全新商业秩序
    mission: 'หน้าที่', //使命
    missionTip: 'ให้ทุกคนได้เงินแบบสบายๆ', //让人人都轻松赚钱
    contactUs: 'ติดต่อเรา', //与我们取得联系
    contact: 'ติดต่อ', //联系
    ReceiveTime: 'เวลาทำการ 09.00 - 18.00 น. (หยุดวันเสาร์-อาทิตย์และวันหยุดนักขัตฤกษ์)', //接待时间：9:00-18:00(周六、周日及公众假期休息)
    Mailbox: 'อีเมล', //总务邮箱
    harken: 'เรายินดีรับฟัง', //我们用心倾听
    proposal:
      'ไม่ว่าจะเป็นข้อมูลเกี่ยวกับผลิตภัณฑ์ การออกแบบ หรือกฎระเบียบบนแพลตฟอร์ม หรือแม้แต่เสนอฟังก์ชันที่คุณต้องการ ก็สามารถแสดงความคิดเห็นของคุณได้ที่นี่', //无论是产品、设计、还是平台规则，或是你期待的功能，发表你的想法。
    award: 'ความคิดเห็นที่เป็นประโยชน์มีสิทธิ์รับรางวัล 500 - 5000 เหรียญMXCOME', //有效建议采纳将有机会获得500-5000淘金金币奖励
    configSuggest: 'แนะนำตอนนี้', //提交建议
    office: 'บริษัทของเรา', //我们的办公室
    address: 'ซอย รามอินทรา 32 แยก6แขวงท่าแร้ง เขตบางเขน กรุงเทพมหานคร 10220', //77/3 Soi Chuchitarom Alley Ratchadaphisek Subdistrict Din Dang District Bangkok 10400
    attention: 'ติดตามเรา', //关注我们
    moreContent: 'เนื้อหาที่น่าตื่นเต้นยิ่งขึ้น', //更多精彩内容
    userSuggest: 'ความคิดเห็นจากผู้ใช้', //用户建议
    name: 'ชื่อ-นามสกุล', //姓名
    region: 'ประเทศ', //区域
    thai: 'ไทย', //泰国
    china: 'จีน', //中国
    ID: 'ไอดี MXCOME', //MXCOME ID
    phone: 'เบอร์ติดต่อ', //联系电话
    suggestContent: 'กรุณากรอกข้อความความคิดเห็น', //请以文字描述建议内容
    upload: 'อัปโหลดไฟล์', //上传附件
    uploadTips: 'รองรับไฟล์ประเภท PDF WORD ขนาดไม่เกิน 10 MB', //仅限10MB大小，文件格式支持（PDF WORD）
    submit: 'ส่ง', //提交
    GoHere: 'ทางนี้', // 去这里
    copySuccess: 'สําเนาสําเร็จ',
    copy_link: 'คัดลอกลิงค์' //复制链接
  },
  join: {
    joinM: 'เข้าร่วม MXCOME',
    welcome: 'ยินดีต้อนรับเข้าสู่ครอบครัว MXCOME',
    btnThai: 'ประเทศไทย',
    btnCh: 'จีน',
    changeWork: 'อยากเปลี่ยนงาน? MXCOMEขอเชิญคุณมาเป็นส่วนหนึ่งกับเรา',
    joinUs: 'เข้าร่วมกับเรา',
    whichWork: 'งานที่คุณมองหา',
    JobTitle: 'กรุณาใส่ตำแหน่งงาน',
    addressDetail: 'ที่อยู่', //详细地址
    workplacePlaceholder: 'โปรดเลือกสำนักงานในเมือง', //请选择城市办公室
    workplace: 'สถานที่ทำงาน', //工作地点
    thai: 'ประเทศไทย',
    china: 'จีน',
    usa: 'อเมริกา',
    BangkokStudio: 'สตูดิโอกรุงเทพ', //曼谷工作室
    ChangshaStudio: 'ฉางซา สตูดิโอ', //长沙工作室
    ShenzhenStudio: 'สตูดิโอเซินเจิ้น', //深圳工作室
    required: 'คุณสมบัติและความสามารถ',
    openPositions: 'ตำแหน่งที่เปิด', //开放岗位
    controlCenter: 'A.ศูนย์กลางการควบคุม', //管理中心
    ProductCenter: 'T.ศูนย์รวมสินค้า', //产品中心
    businessCenter: 'B.ศูนย์ธุรกิจ', //商业中心
    department: 'แผนก',
    design: '设计',
    research: '研发', //research and development
    assetManagement: '资管',
    hr: '人资',
    generalOffice: '总办', //General Office
    planning: '企划',
    operate: '运营',
    choose: 'กรุณาเลือก',
    type: 'ประเภทงาน',
    FullTime: 'งานประจำ',
    partTime: 'งานชั่วคราว',
    practice: 'ฝึกงาน',
    recruitmentPolicies: 'นโยบายการรับสมัคร',
    procedure: 'ขั้นตอนการรับเข้าทำงาน',
    redLine: 'จรรยาบรรณวิชาชีพ',
    markAsHoliday: 'ทำเครื่องหมายเป็นวันหยุด', //标记为放假日
    somePublicDaysHaveAdjust: 'วันหยุดนักขัตฤกษ์บางวันมีการปรับเปลี่ยน', //部分公众日存在调整
    holidayCalendar: 'ปฏิทินวันหยุด' //放假日历
  },
  collaboration: {
    invite: 'ต้อนรับเข้าสู่โลกของ MXCOME ',
    together: 'เคร่งครัด จริงใจ เพื่อมอบประโยชน์และสร้างอนาคตร่วมกัน',
    multi: 'ความร่วมมือแบบพหุมิติ',
    thai: 'ประเทศไทย',
    china: 'ประเทศจีน',
    interaction: 'ความร่วมมือกิจกรรม',
    platformOnboard: 'การเริ่มต้นใช้งานแพลตฟอร์ม', //平台入驻
    events: 'จัดกิจกรรมพิเศษของแบรนด์',
    information: 'ส่งข้อมูล',
    circular: 'ประกาศผลตรวจสอบอย่างเป็นทางการ',
    only: 'รองรับไฟล์ประเภท PDF WORD EXCEL ขนาดไม่เกิน 10 MB',
    search: 'ค้นหาชื่อแบรนด์',
    category: 'ประเภท',
    // 平台合作
    TypeCooperation: 'ประเภทการเข้าร่วม', //合作类型
    brand: 'แบรนด์', //品牌
    CheckInTime: 'วันที่เข้าร่วม', //入驻时间
    OfficialAuditCircular: 'ประกาศผลการตรวจสอบ', //官方审核通告
    BrandSettlement: 'แบรนด์เข้าร่วม', //品牌入驻
    BrandHome: 'ประเทศที่มาของแบรนด์', //品牌归属地
    BrandName: 'ชื่อแบรนด์', //品牌名称
    ContactName: 'ชื่อผู้ติดต่อ', //联系人名称
    AreaCode: 'รหัสประเทศ', //区号
    ContactNumber: 'เบอร์โทร', //联系电话
    emailBrand: 'อีเมล', //邮箱
    webBrand: 'เว็บไซต์แบรนด์', //品牌官网
    SubmitApplication: 'ส่งคำขอ', //提交申请
    YourApplicationPleaseWaitGoodNews: 'ส่งคำขอของท่านเรียบร้อยแล้ว กรุณารอทีมงานติดต่อกลับ', //您的申请已提交，请静候佳音
    PleaseKeepPhoneOpen: 'เจ้าหน้าที่อาจติดต่อกลับหากต้องการข้อมูลเพิ่มเติม กรุณารอรับสายเจ้าหน้าที่', //请保存电话畅通，如有必要我们将向您致电
    PleaseKeepPhoneOpen2: 'เจ้าหน้าที่จะตรวจสอบเสร็จสิ้นภายใน 48 ชั่วโมง ท่านจะได้รับข้อความ SMS และอีเมลยืนยันจากเรา', //我们将于48小时内完成审核，并通过短信及邮件方式发送
    know: 'รับทราบ', //知道了
    LOGO: 'โลโก้',
    deliveryTime: 'เวลาจัดส่ง',
    result: 'ผลการตรวจสอบ',
    remark: 'หมายเหตุ',
    pass: 'ผ่าน',
    reject: 'ถูกปฏิเสธ',
    SONY: 'SONY ประเทศไทย'
  }
}
