<!-- 
  官网网红加入弹框
  neme hlz
  2023/12/12 
 -->
<template>
  <div>
    <el-dialog title="" :visible.sync="dialogVisible" width="70%" :show-close="false">
      <div v-if="dialogVisible">
        <div class="internetCelebrity-joinHead">
          <img src="@/assets/internetCelebrity-12-12.png" alt="" />
        </div>
        <div class="internetCelebrityJoin-content">
          <div class="content-head">
            <!-- 网红加入 -->
            {{ $t('joinUs.Internet_celebrity_joining') }}
          </div>
          <div class="content">
            <div class="left">
              <el-form :model="from" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                <!-- 联系人姓名 -->
                <imput
                  style="height: 50px"
                  class="imputS"
                  :name="'name'"
                  label="partenship.contactName"
                  @input="get"
                  :value="from.name"
                  :placeholder="$t('partenship.contactName')"
                />
                <!-- 所在地区 -->
                <imput
                  style="height: 50px"
                  :state="'select'"
                  :name="'region'"
                  label="joinUs.area"
                  @input="get"
                  :value="from.region"
                  :options="typeList"
                  :placeholder="$t('joinUs.please_select_your_region')"
                />
                <!-- 输入联系电话 -->
                <imput
                  style="height: 50px"
                  :state="'phone'"
                  :name="'phone'"
                  @input="get"
                  :value="from.phone"
                  :placeholder="$t('partenship.enterContactPhoneNumber')"
                />
                <!-- email -->
                <imput
                  style="height: 50px"
                  :name="'email'"
                  label="partenship.email"
                  @input="get"
                  :value="from.email"
                  :placeholder="$t('partenship.email')"
                />
              </el-form>
            </div>
            <div class="right">
              <div class="right-head">
                <span>
                  <!-- 当日频道截图 -->
                  {{ $t('joinUs.screenshot_of_Today_s_Channel') }}
                </span>
                <span class="view-examples" @click="show = true">
                  <!-- 查看示例 -->
                  {{ $t('joinUs.view_examples') }}
                </span>
              </div>
              <div class="upload-box">
                <div v-for="(item, index) in uploadList" :key="index">
                  <upload @uploadFile="get" :valueName="item.value" :backgroundUrl="item.img" :titleText="item.titleText" />
                </div>
              </div>
              <div class="prompt-box">
                <ul>
                  <li v-for="(items, i) in textList" :key="i">{{ $t(items.name) }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="btn-box">
            <el-button @click="submit" :class="btnState ? 'on-btn' : 'btn'" type="info" round>
              <!-- 提交  -->
              {{ $t('aboutUs.submit') }}
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <div class="example-box" v-if="show">
      <img src="@/assets/mxcomeImg/joinUs/20231212-1238421.png" alt="" />
      <div style="margin-top: 5%">
        <el-button @click="show = false" class="on-btn" type="info" round>
          <!-- 知道了 -->
          {{ $t('collaboration.know') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { props } from '@xkeshi/vue-qrcode'
import { mapState } from 'vuex'
import { influencerJoin } from '../api/index.js'
export default {
  components: {
    imput: () => import('@/components/input.vue'),
    upload: () => import('./upload.vue')
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // dialogVisible: true, // 网红加入弹框控制
      show: false,
      typeList: [
        { value: 1, label: 'aboutUs.thai' },
        { value: 2, label: 'aboutUs.china' }
      ],
      // 表单数据
      from: {
        name: '', //	联系人姓名
        phoneCode: '', //	区号
        phone: '', //	联系人电话
        region: '', //	所在地区
        email: '', //	联系人邮箱
        tiktokPicUrl: '', //	tikTok实例截图rul
        instgramPicUrl: '', //	instgram实例截图rul
        youtubePicUrl: '', //	Youtube实例截图rul
        facebookPicUrl: '', //	facebook实例截图rul
        lineOaPicUrl: '', //	lineOa实例截图rul
        xPicUrl: '' //	X实例截图rul
      },
      // 表单校验
      rules: {
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        phone: [
          { required: true, message: ' ', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: ' '
          }
        ],
        region: [{ required: true, message: ' ', trigger: 'change' }],
        email: [{ required: true, message: ' ', trigger: 'blur' }]
      },
      limit: '',
      // 平台图片上传
      uploadList: [
        {
          img: require('@/assets/mxcomeImg/joinUs/logos_tiktok-icon.png'), // 图片路径
          value: 'tiktokPicUrl', // 上传路径
          titleText: '+ TikTok' // title文字
        },
        {
          img: require('@/assets/mxcomeImg/joinUs/skill-icons_instagram.png'), // 图片路径
          value: 'instgramPicUrl', // 上传路径
          titleText: '+ instgram' // title文字
        },
        {
          img: require('@/assets/mxcomeImg/joinUs/logos_youtube-icon.png'), // 图片路径
          value: 'youtubePicUrl', // 上传路径
          titleText: '+ Youtube' // title文字
        },
        {
          img: require('@/assets/mxcomeImg/joinUs/logos_facebook.png'), // 图片路径
          value: 'facebookPicUrl', // 上传路径
          titleText: '+ facebook' // title文字
        },
        {
          img: require('@/assets/mxcomeImg/joinUs/LINE_logo 1.png'), // 图片路径
          value: 'lineOaPicUrl', // 上传路径
          titleText: '+ Line OA' // title文字
        },
        {
          img: require('@/assets/mxcomeImg/joinUs/X_logo_2023_original 2.png'), // 图片路径
          value: 'xPicUrl', // 上传路径
          titleText: '+ X' // title文字
        }
      ],
      textList: [
        {
          name: 'joinUs.text5'
        },
        {
          name: 'joinUs.text6'
        },
        {
          name: 'joinUs.text7'
        },
        {
          name: 'joinUs.text4'
        }
      ],
      btnState: false
    }
  },

  computed: {
    ...mapState(['lang'])
  },
  watch: {
    show(newVal) {
      console.log(newVal)
    },
    dialogVisible(newVal) {
      console.log(newVal)
      if (newVal == false) {
        this.$emit('internetCelebrityJoin', false)
        switch (this.lang) {
          case 'zh':
            this.from.phoneCode = 86
            this.rules.phone[1].pattern = /^1[3456789]\d{9}$/
            break
          case 'thai':
            this.from.phoneCode = 66
            this.rules.phone[1].pattern = /\d{9,10}$/
            break
          case 'en':
            this.from.phoneCode = 1
            this.rules.phone[1].pattern = /\d{6,}$/
            break
          default:
            break
        }
      }
    },
    lang(newVal) {
      switch (newVal) {
        case 'zh':
          this.rules.phone[1].pattern = /^1[3456789]\d{9}$/
          break
        case 'thai':
          this.rules.phone[1].pattern = /\d{9,10}$/
          break
        case 'en':
          this.rules.phone[1].pattern = /\d{6,}$/
          break
        default:
          break
      }
    },
    from: {
      handler(val) {
        console.log(val)
        if (val.phone && val.email && val.name && val.region) {
          this.btnState = true
        } else {
          this.btnState = false
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 获取参数
    get(val, value, phoneCode) {
      debugger
      if (val == 'officialWebsite') {
        this.from[val] = this.from[val].length > 0 ? +',' + value : value
      }
      if (val == 'phone') {
        if (phoneCode == '86') {
          this.rules.phone[1].pattern = /^1[3456789]\d{9}$/
        }
        if (phoneCode == '66') {
          this.rules.phone[1].pattern = /\d{9,10}$/
        }
        if (phoneCode == '1') {
          this.rules.phone[1].pattern = /\d{6,}$/
        }
        this.from[val] = value
        this.from.phoneCode = phoneCode
        return
      }
      this.from[val] = value
    },
    // 提交事件
    submit() {
      console.log(this.from)
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          influencerJoin(this.from).then((res) => {
            if (res.code == 200) {
              this.$message.success(this.$t('submitted_Successfully'))
              this.$emit('internetCelebrityJoin', false)
              this.from = {
                name: '', //	联系人姓名
                phoneCode: '', //	区号
                phone: '', //	联系人电话
                region: '', //	所在地区
                email: '', //	联系人邮箱
                tiktokPicUrl: '', //	tikTok实例截图rul
                instgramPicUrl: '', //	instgram实例截图rul
                youtubePicUrl: '', //	Youtube实例截图rul
                facebookPicUrl: '', //	facebook实例截图rul
                lineOaPicUrl: '', //	lineOa实例截图rul
                xPicUrl: '' //	X实例截图rul
              }
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 16px;
  background: var(--white-100, #fff);
}
.internetCelebrity-joinHead {
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    // height: 100%;
    width: 100%;
  }
}
/deep/.el-dialog__header {
  padding: 0;
}
/deep/.el-dialog__body {
  padding: 0;
}
.internetCelebrityJoin-content {
  padding: 40px 59px;
  .content-head {
    text-align: left;
    color: #292929;
    font-size: 20px;
    font-weight: 600;
  }
  .content {
    display: flex;
    margin-top: 30px;
    .left {
      width: 50%;
      padding-right: 30px;
      border-right: 1px solid #f0f0f0;
      /deep/.imputS {
        .input-box {
          margin-top: 0;
        }
      }
    }
    .right {
      width: 50%;
      padding-left: 30px;
      .right-head {
        display: flex;
        justify-content: space-between;
        color: #292929;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
        .view-examples {
          color: #358df4;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
        }
      }
      .upload-box {
        width: 90%;
        /* 声明一个容器 */
        display: grid;
        justify-content: left;
        align-content: center;
        /*  声明列的宽度  */
        grid-template-columns: repeat(3, 20%);
        /*  声明行间距和列间距  */
        grid-gap: 20px;
      }
      .prompt-box {
        margin-top: 10px;
        padding-bottom: 35px;
        li {
          text-align: left;
          color: #292929;
          font-size: 14px;
          font-weight: 500;
          margin-top: 20px;
        }
      }
    }
  }
  .btn-box {
    text-align: center;
    margin-top: 50px;
    .btn {
      margin-top: 50px;
      width: 30%;
      margin: 0;
      background: #f5f5f5;
      border-color: #f5f5f5;
      color: rgba(41, 41, 41, 0.38);
      font-size: 20px;
    }
    .on-btn {
      margin-top: 50px;
      width: 30%;
      margin: 0;
      color: #fff;
      background: #f45656;
      font-size: 20px;
      border-color: #f45656;
    }
  }
}
.example-box {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10000;
  position: fixed;
  top: 0;
  img {
    margin-top: 5%;
    width: 300px;
  }
  .on-btn {
    font-size: 20px;
    font-weight: 600;
    width: 10%;
    margin: 0;
    color: #f45656;
    background: #fff;
    font-size: 20px;
  }
}
</style>
