import Axios from 'axios'
import ElementUI from 'element-ui'
// const baseURL = 'http://192.168.31.101:8201'
const baseURL = 'https://gateway.mxcome.com'
// const baseURL = 'https://app-api.mxcome.com'
const axios = Axios.create({
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  },
  timeout: 6000, // 请求超时
  baseURL: baseURL
})

// 请求拦截
axios.interceptors.request.use((req) => {
  if (req.method === 'get') {
    const url = req.url
    // const t = new Date().getTime()
    if (url.indexOf('?') >= 0) {
      req.url = `${url}`
    } else {
      req.url = `${url}`
    }
  }
  return req
})

// 响应拦截
axios.interceptors.response.use(
  (response) => {
    let res = response.data
    if (response.config.responseType === 'blob') {
      return res
    }
    if (typeof res === 'string') {
      res = res.data ? JSON.parse(res.data) : res.data
    }
    return res
  },
  (error) => {
    // 失败统一处理
    console.log('err' + error)
    return Promise.reject(error)
  }
)

export default axios
