<template>
  <div v-if="barCodeShow" class="hello">
    <!-- <vue-qr v-if="barCodeShow" ref="qrCodeUrl" :text="dataObj.text" :logoSrc="dataObj.logo" :callback="test" qid="testQrId"></vue-qr> -->
    <div class="qrcode" ref="qrCodeUrl"></div>
  </div>
</template>

<script>
// 二维码组件
import VueQr from 'vue-qr'
import QRCode from 'qrcodejs2'
export default {
  name: 'HelloWorld',
  components: { VueQr },
  props: {
    product: [String, Number],
    pageName: String,
    barCodeShow: Boolean
  },
  data() {
    return {
      dataObj: {
        text: `mxcome://web/?productId=${this.product ? this.product : null}&pageName=${this.pageName}`,
        logo: ''
      }
    }
  },
  watch: {
    product(newval, lodval) {
      this.creatQrCode()
    }
  },
  mounted() {
    this.creatQrCode()
  },
  methods: {
    test(dataUrl, id) {
      console.log(dataUrl)
      console.log(id)
    },
    // 调用生成二维码,必须使用$nextTick
    creatQrCode() {
      this.$refs.qrCodeUrl.innerHTML = ''
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: `mxcome://web/?productId=${this.product ? this.product : null}&pageName=${this.pageName}`, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
