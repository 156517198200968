<template>
  <div class="main-page">
    <down-info></down-info>
    <!-- <activity-info></activity-info> -->
    <!-- <div class="slogan">
            <p>创新 改变 未来</p>
        </div> -->
  </div>
</template>
<script>
import downInfo from './components/activity/downInfo.vue'
// import activityInfo from './components/activity/activityInfo.vue'
export default {
  name: 'ActivityView',
  components: {
    downInfo
    // activityInfo
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.main-page {
  min-width: 980px;
}
.slogan {
  padding: 40px 0;
  display: flex;
  justify-content: center;
  p {
    padding: 10px;
    width: 50%;
    background: #f5f5f5;
    border-radius: 32px;
    font-size: 21px;
    font-weight: 600;
  }
}
</style>
