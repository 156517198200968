import Vue from 'vue'
import VueI18n from 'vue-i18n'

import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from './zh'
import enLocale from './en'
import thaiLocale from './Thai'
Vue.use(VueI18n)

let lang = localStorage.getItem('lang') || 'thai'
const i18n = new VueI18n({
  locale: lang,
  // locale: navigator.language,
  fallbackLocale: 'thai',
  silentFallbackWarn: true,
  messages: {
    zh: {
      ...zhLocale,
      ...elementZhLocale
    },
    en: {
      ...enLocale,
      ...elementEnLocale
    },
    thai: {
      ...thaiLocale
    }
  }
})

export default i18n
