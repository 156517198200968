export default {
  navList: [
    {
      labelName: 'menus.activity',
      value: 'home'
    },
    {
      labelName: 'menus.partenship',
      value: 'partenship'
    },
    {
      labelName: 'menus.aboutUs',
      value: 'aboutUs'
    },
    {
      labelName: 'menus.news',
      value: 'newsCenter'
    },
    {
      labelName: 'menus.joinUs',
      value: 'joinUs'
    }
  ],
  langList: [
    {
      label: '中文',
      value: 'zh',
      logoSrc: require('@/assets/zh.png')
    },
    {
      label: 'English',
      value: 'en',
      logoSrc: require('@/assets/en.png')
    },
    {
      label: 'ไทย',
      value: 'thai',
      logoSrc: require('@/assets/thai.png')
    }
  ],
  //  区号
  phoneCodeArr: [
    {
      name: 'merchant.tai',
      code: '+66'
    },
    {
      name: 'merchant.zh',
      code: '+86'
    },
    {
      name: 'merchant.en',
      code: '+1'
    }
  ],
  spiritOptions: [
    // {
    //     title: 'aboutUs.message',
    //     srcUrl: require("@/assets/Spirit1.png"),
    //     content: 'aboutUs.messageTip'
    // },
    {
      srcUrl: require('@/assets/Spirit16.png'),
      title: 'aboutUs.vision',
      content: 'aboutUs.visionTip'
    },
    {
      srcUrl: require('@/assets/Spirit15.png'),
      title: 'aboutUs.values',
      content: 'aboutUs.valuesTip',
      content1: 'aboutUs.valuesTip1',
      percent: '50%'
    },
    {
      srcUrl: require('@/assets/Spirit14.png'),
      title: 'aboutUs.mission',
      content: 'aboutUs.missionTip'
    }
  ],
  leaderOptions: [
    {
      srcUrl: require('@/assets/userImages/TerryYang.png'),
      name: 'Terry Yang',
      positions: 'CFO',
      btnLabel: 'aboutUs.contact'
    },
    {
      srcUrl: require('@/assets/userImages/LuckyCao.png'),
      name: 'Lucky Cao',
      positions: 'COO',
      btnLabel: 'aboutUs.contact'
    }
  ],
  newsNav: [
    {
      label: 'news.productNews'
    },
    {
      label: 'news.dynamic'
    },
    {
      label: 'news.report'
    },
    {
      label: 'news.statement'
    }
  ],
  newsList: [
    {
      title: '法拉第未来称达成一项3.5亿美元备用股权信贷额',
      content:
        '11月15日消息，当地时间周一电动汽车初创公司法拉第未来表示，已经签署一项融资总额最多可达3.5亿美元的协议。当下，法拉第未来正努力推出FF 91豪华电动汽车。',
      time: '2022/11/12 22:30'
    },
    {
      title: '现场还原潮州特斯拉“失控”过程 路面两侧覆盖沙土 目击者：地上很多碎片',
      content:
        '11月13日，网上一则特斯拉“失控”视频引起关注，特斯拉方面及司机家属随后发表回应。 特斯拉方面称事发时车辆电门被长期深度踩下，并一度保持100%，全程没有踩下刹车的动作；司机的儿子小詹则表示，司机自述当时踩了刹车，但刹车“没有反应”。',
      time: '2022/11/12 22:30'
    },
    {
      title: '法拉第未来称达成一项3.5亿美元备用股权信贷额',
      content:
        '11月15日消息，当地时间周一电动汽车初创公司法拉第未来表示，已经签署一项融资总额最多可达3.5亿美元的协议。当下，法拉第未来正努力推出FF 91豪华电动汽车。',
      time: '2022/11/12 22:30'
    },
    {
      title: '现场还原潮州特斯拉“失控”过程 路面两侧覆盖沙土 目击者：地上很多碎片',
      content:
        '11月13日，网上一则特斯拉“失控”视频引起关注，特斯拉方面及司机家属随后发表回应。 特斯拉方面称事发时车辆电门被长期深度踩下，并一度保持100%，全程没有踩下刹车的动作；司机的儿子小詹则表示，司机自述当时踩了刹车，但刹车“没有反应”。',
      time: '2022/11/12 22:30'
    }
  ],
  addressOptions: [
    {
      label: 'join.BangkokStudio',
      value: 'Thai'
    },
    {
      label: 'join.ChangshaStudio',
      value: 'CN'
    },
    {
      label: 'join.ShenzhenStudio',
      value: 'USA'
    }
  ],
  abilityOptions: [
    {
      label: 'CSS',
      value: 'CSS'
    },
    {
      label: 'Java',
      value: 'Java'
    },
    {
      label: 'Python',
      value: 'Python'
    }
  ],
  //开放岗位
  openPositions: [
    {
      label: 'join.controlCenter',
      value: 'controlCenter'
    },
    {
      label: 'join.ProductCenter',
      value: 'ProductCenter'
    },
    {
      label: 'join.businessCenter',
      value: 'businessCenter'
    }
  ],
  //部门
  departments: [
    {
      label: 'join.design',
      value: 'design'
    },
    {
      label: 'join.research',
      value: 'research'
    },
    {
      label: 'join.assetManagement',
      value: 'assetManagement'
    },
    {
      label: 'join.hr',
      value: 'hr'
    },
    {
      label: 'join.generalOffice',
      value: 'generalOffice'
    },
    {
      label: 'join.planning',
      value: 'planning'
    },
    {
      label: 'join.operate',
      value: 'operate'
    }
    // { label: 'join.controlCenter', value: 'controlCenter' },
    // { label: 'join.ProductCenter', value: 'ProductCenter' },
    // { label: 'join.businessCenter', value: 'businessCenter' }
    // design: 'design',
    // research: 'research and development',
    // assetManagement: 'asset management',
    // hr: 'hr',
    // generalOffice: 'general office',//General Office
    // planning: 'planning',
    // operate: 'operate',
  ],
  typeOptions: [
    {
      label: 'join.FullTime',
      value: '1'
    },
    {
      label: 'join.partTime',
      value: '2'
    },
    {
      label: 'join.practice',
      value: '3'
    }
  ],
  jobsList: [
    {
      title: '全球供应链经理，机械操作，消费类硬件',
      time: '2022/11/12 22:30',
      address: '泰国',
      requirements:
        '拥有机械工程、电气工程或相关专业的学士学位，或具备同等水平的实践经验。<br/> 拥有 5 年消费类电子产品全球供应链管理经验。 <br/> 拥有玻璃及脆性材料、金属数控加工、金属注射成型、注塑成型、喷涂、模切、制造工艺和供应链流程方面的经验。 <br/> 能够根据需要出差。',
      duty: '积极推动我们与全球机械供应商进行商业谈判和签订协议时的各方面工作；管理总体机械物料支出和相关资本投资；制定并执行全球供应链战略。 <br/>管理供应商，确保生产准备工作均已就绪、供应有保证并且总体质量符合要求，以便为新产品开发 (NPI) 试产和可持续运营提供支持。 <br/>推动与内部合作伙伴（包括工程团队、市场营销团队和运营团队）建立密切的合作关系，从而推动打造具有成本效益的高质量创新解决方案，助力实现我们的总体产品发布和可持续发展目标。 <br/>应对供应链风险，同时打造灵活且响应迅速的供应链解决方案。借助应需成本模型推动降低成本，并对合作伙伴施加积极影响，助力打造具有成本效益的供应链解决方案。'
    },
    {
      title: '测试技术项目经理，核心测试工程',
      time: '2022/11/12 22:30',
      address: '泰国',
      requirements:
        '拥有电气工程或计算机科学专业的学士学位，或具备同等水平的实践经验 <br/> 拥有 4 年在制造行业或消费类电子产品行业从事工程相关工作的经验 <br/> 拥有制造测试和项目管理经验',
      duty: '积极推动我们与全球机械供应商进行商业谈判和签订协议时的各方面工作；管理总体机械物料支出和相关资本投资；制定并执行全球供应链战略。 <br/>管理供应商，确保生产准备工作均已就绪、供应有保证并且总体质量符合要求，以便为新产品开发 (NPI) 试产和可持续运营提供支持。 <br/>推动与内部合作伙伴（包括工程团队、市场营销团队和运营团队）建立密切的合作关系，从而推动打造具有成本效益的高质量创新解决方案，助力实现我们的总体产品发布和可持续发展目标。 <br/>应对供应链风险，同时打造灵活且响应迅速的供应链解决方案。借助应需成本模型推动降低成本，并对合作伙伴施加积极影响，助力打造具有成本效益的供应链解决方案。'
    },
    {
      title: '全球供应链经理，机械操作，消费类硬件',
      time: '2022/11/12 22:30',
      address: '泰国',
      requirements:
        '拥有机械工程、电气工程或相关专业的学士学位，或具备同等水平的实践经验。<br/> 拥有 5 年消费类电子产品全球供应链管理经验。 <br/> 拥有玻璃及脆性材料、金属数控加工、金属注射成型、注塑成型、喷涂、模切、制造工艺和供应链流程方面的经验。 <br/> 能够根据需要出差。',
      duty: '积极推动我们与全球机械供应商进行商业谈判和签订协议时的各方面工作；管理总体机械物料支出和相关资本投资；制定并执行全球供应链战略。 <br/>管理供应商，确保生产准备工作均已就绪、供应有保证并且总体质量符合要求，以便为新产品开发 (NPI) 试产和可持续运营提供支持。 <br/>推动与内部合作伙伴（包括工程团队、市场营销团队和运营团队）建立密切的合作关系，从而推动打造具有成本效益的高质量创新解决方案，助力实现我们的总体产品发布和可持续发展目标。 <br/>应对供应链风险，同时打造灵活且响应迅速的供应链解决方案。借助应需成本模型推动降低成本，并对合作伙伴施加积极影响，助力打造具有成本效益的供应链解决方案。'
    },
    {
      title: '测试技术项目经理，核心测试工程',
      time: '2022/11/12 22:30',
      address: '泰国',
      requirements:
        '拥有电气工程或计算机科学专业的学士学位，或具备同等水平的实践经验 <br/> 拥有 4 年在制造行业或消费类电子产品行业从事工程相关工作的经验 <br/> 拥有制造测试和项目管理经验',
      duty: '积极推动我们与全球机械供应商进行商业谈判和签订协议时的各方面工作；管理总体机械物料支出和相关资本投资；制定并执行全球供应链战略。 <br/>管理供应商，确保生产准备工作均已就绪、供应有保证并且总体质量符合要求，以便为新产品开发 (NPI) 试产和可持续运营提供支持。 <br/>推动与内部合作伙伴（包括工程团队、市场营销团队和运营团队）建立密切的合作关系，从而推动打造具有成本效益的高质量创新解决方案，助力实现我们的总体产品发布和可持续发展目标。 <br/>应对供应链风险，同时打造灵活且响应迅速的供应链解决方案。借助应需成本模型推动降低成本，并对合作伙伴施加积极影响，助力打造具有成本效益的供应链解决方案。'
    }
  ],
  detailData: {
    title: '商业旗舰-MXCOME 全球首家web3社交推荐平台',
    time: '2022/11/12 22:30',
    content:
      '11月15日消息，当地时间周一电动汽车初创公司法拉第未来表示，已经签署一项融资总额最多可达3.5亿美元的协议。 <br/><br/>当下，法拉第未来正努力推出FF 91豪华电动汽车。该公司表示，从投资管理公司Yorkville Advisors Global一家关联公司获得的融资，让法拉第未来在启动电动汽车生产以及产品交付方面拥有更多财务灵活性。 <br/><br/>这项备用股权信贷额度协议的初始承诺为2亿美元，可根据公司的选择增加到3.5亿美元。 <br/><br/>法拉第未来刚刚于今年9月份解决了一场公司内部治理纠纷，并筹集到1亿美元。此前公司表示，一场“虚假信息运动”和对部分董事会成员的威胁影响到公司融资。 <br/><br/>上周，法拉第未来收到纳斯达克股票市场的通知，称公司股票未能连续30个交易日保持每股至少1美元的最低价，最终股东们批准反向拆股的提议。（辰辰）'
  },
  tableData: [
    {
      category: '承办品牌专场',
      origin: 'Red Bull',
      logoSrc: require('@/assets/RedBull.png'),
      sendTime: '2022/11/12 22:30',
      result: 0,
      remark: ''
    },
    {
      category: '承办品牌专场',
      origin: 'SONY泰国',
      logoSrc: require('@/assets/SONY.png'),
      sendTime: '2022/11/12 22:30',
      result: 1,
      remark: ''
    }
  ]
}
