<!-- 
  官网聊天功能组件
  neme hlz
  2023/10/9 
 -->
<template>
  <!-- 活动table -->
  <div @click="onClick(listData, index)" :class="[clickState == index ? 'selectedTable' : '', 'table']">
    <div class="title">
      <img :src="listData.shopLogo" alt="" />
      <span>{{ listData.shopName }}</span>
    </div>
    <!-- 内容部分 -->
    <div class="text-box">
      <div>
        <img :src="listData.pocketProductPic" alt="" />
      </div>
      <div class="content-box">
        <span class="title-text">{{ listData.pocketProductName }}</span>
        <div class="content-text">
          <span>
            ฿{{ handleData(listData.price) }}
            <span class="text">฿{{ handleData(listData.guidingPrice) }}</span>
          </span>
          <div class="icon-text">{{ handleDivideMoist(listData.ratio) }}%</div>
        </div>
        <span class="price-box">
          <svg class="icon" aria-hidden="true" style="padding-top: 3px; height: 15px; width: 15px">
            <use xlink:href="#icon-a-zu22146"></use>
          </svg>
          <span class="text">฿{{ processingData(listData.memberProfitAmount) }}/件</span>
        </span>
      </div>
    </div>
    <!-- 底部部分 -->
    <div class="bottom-box">
      <div class="bottom-box-left">
        <svg class="icon" aria-hidden="true" style="padding-top: 3px; height: 23px; width: 23px">
          <use xlink:href="#icon-time_icon"></use>
        </svg>
        <span>{{ date }}</span>
      </div>
      <div class="bottom-box-right" v-if="listData.haveGift == 1">
        <svg class="icon" aria-hidden="true" style="padding-top: 3px; height: 23px; width: 23px">
          <use xlink:href="#icon-gift_icon"></use>
        </svg>
        <p>
          {{ $t('home.recommendationRewards') }}
          <span style="color: #b2b2b2">
            {{ $t('home.deal') }}
            <span style="color: #f45656">
              {{ listData.num ? listData.num : 0 }}
            </span>
            {{ $t('home.one') }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    listData: {
      type: Object,
      default: Object
    },
    index: {
      type: [String, Number],
      default: ''
    },
    clickState: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      date: '', // 倒计时
      endTimeSecond: this.listData.endTimeSecond ? this.listData.endTimeSecond : '',
      time: null
    }
  },
  created() {
    this.time = setInterval(() => {
      this.getEndTime()
    }, 1000)
  },
  beforeDestroy() {
    window.clearInterval(this.time)
  },
  methods: {
    // 保留两位小数
    processingData(val) {
      return val.toFixed(2)
    },
    // 数据处理
    handleData(val) {
      return val.toFixed(2)
    },
    //tab点击事件
    onClick(val, i) {
      this.$emit('onClick', val, i)
    },
    getEndTime() {
      if (this.endTimeSecond <= 1) {
        window.clearInterval(this.time)
        return
      }
      this.endTimeSecond = --this.endTimeSecond

      let value = this.endTimeSecond
      let days = parseInt(value / 86400) // 天  24*60*60*1000
      let hours = parseInt(value / 3600) - 24 * days // 小时 60*60 总小时数-过去的小时数=现在的小时数
      let minutes = parseInt((value % 3600) / 60) // 分钟 -(day*24) 以60秒为一整份 取余 剩下秒数 秒数/60 就是分钟数
      let seconds = parseInt(value % 60) // 以60秒为一整份 取余 剩下秒数
      // console.log(days + '天' + hours + ':' + minutes + ':' + seconds)
      // console.log(days + this.$t('home.day') + hours + ':' + minutes + ':' + seconds)
      this.date = days + this.$t('home.day') + hours + ':' + minutes + ':' + seconds
    },
    handleDivideMoist(val) {
      let data = val * 100

      return data.toFixed(2)
    }
  }
}
</script>
<style lang="less" scoped>
.selectedTable {
  border: 1px solid #f45656;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12) !important;
  cursor: pointer;
}
.table {
  margin-top: 30px;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  .title {
    display: flex;
    justify-content: left;
    padding: 6px 15px;
    background: var(--black-f-5, #f5f5f5);
    img {
      width: 32px;
      height: 32px;
    }
    span {
      margin-left: 10px;
      color: var(--black-100, #292929);
      font-size: 20px;
      font-weight: 600;
    }
  }
  .text-box {
    padding-left: 20px;
    display: flex;
    img {
      height: 120px;
      width: 120px;
      border-radius: 6px;
      overflow: hidden;
    }
    .content-box {
      width: 100%;
      display: flex;
      padding-left: 20px;
      flex-direction: column;
      text-align: left;
      .title-text {
        color: var(--black-100, #292929);
        font-size: 18px;
        font-weight: 590;
      }
      .content-text {
        display: flex;
        justify-content: space-between;
        color: var(--black-100, #292929);
        font-size: 16px;
        font-weight: 510;
        margin: 21px 0;
        .text {
          color: #b2b2b2;
          font-size: 14px;
          text-decoration: line-through;
          font-weight: 400;
          margin-left: 20px;
        }
        .icon-text {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--white-100, #fff);
          font-size: 14px;
          font-weight: 700;
          height: 24px;
          width: 56px;
          background: url('@/assets/mxcomeImg/activity/22506.png') no-repeat;
          background-size: 100% 100%;
          margin-right: 20px;
          min-width: 70px;
          padding-left: 11px;
        }
      }
      .price-box {
        width: 114px;
        padding: 4px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 19px;
        background: var(--mxfef-5, #fef5f5);
        .text {
          font-size: 12px;
          color: var(--mx-100, #f45656);
          font-weight: 590;
          margin-left: 5px;
        }
      }
    }
  }
}
.bottom-box {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #f5f5f5;
  margin-top: 11px;
  .bottom-box-left {
    display: flex;
    align-items: center;
    span {
      font-size: 16px;
      font-weight: 500;
      color: #f45656;
      margin-left: 7px;
    }
  }
  .bottom-box-right {
    display: flex;
    align-items: center;
    p {
      margin-left: 7px;
      font-size: 16px;
      color: #292929;
      font-weight: 400;
    }
  }
}
</style>
