import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: '', // 语言
    uploadUrl: '', // 文件上传地址
    scrollBar: false // 是否有滚动条
  },
  mutations: {
    // 保存语言
    setLang(state, value) {
      state.lang = value
    },
    // 修改文件上传地址
    setUploadUrl(state, value) {
      state.uploadUrl = value
    },
    // 修改有滚动条
    setScrollBar(state, value) {
      state.scrollBar = value
    }
  }
})
