import request from '@/utils/request'

// 列表查询
export function getAllList(params) {
  return request({
    url: '/mall-admin/officialWebsite/getAllList',
    method: 'get',
    params
  })
}

// 部门列表 /mall-admin/officialWebsite/queryDepartment

export function queryDepartment(params) {
  return request({
    url: '/mall-admin/officialWebsite/queryDepartment',
    method: 'get',
    params
  })
}

// 提交申请 /mall-admin/officialWebsite/sendResume
export function sendResume(data) {
  return request({
    url: '/mall-admin/officialWebsite/sendResume',
    method: 'post',
    data
  })
}

// 获取职位详情 /mall-admin/jobPost/update/{id}
export function getJobDetails(id) {
  return request({
    url: `/mall-admin/officialWebsite/getJobInfo/${id}`,
    method: 'get'
  })
}

// 网红加入
export function influencerJoin(data) {
  return request({
    url: `/mall-admin/officialWebsite/influencerJoin`,
    method: 'post',
    data
  })
}
