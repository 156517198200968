<!-- 
  官网底部tab栏
  neme hlz
  2023/11/24
 -->
<template>
  <div class="bottomNavigation">
    <!-- 超级星期三 -->
    <div class="home-box">
      <div v-if="state == 1" style="display: flex; align-items: center">
        <img style="height: 100%" src="~@/assets/mxcomeImg/bottomTab/Rectangle 236.png" alt="" />
        <div class="text-box">
          <span>{{ $t('home.superWednesday') }} </span>
          <span>
            <!-- 活动申请入口 -->
            {{ $t('navigationBottom.event_application_portal') }}
          </span>
        </div>
        <div class="input-box">
          <el-input v-model="input" :placeholder="$t('partenship.Enter')"></el-input>
          <el-button @click="applyFor" round>
            <!-- 申请 -->
            {{ $t('navigationBottom.apply_for') }}
          </el-button>
        </div>
        <div @click="Jump" class="content">湘ICP备19010390号-1</div>
      </div>
      <div v-if="state == 4" style="display: flex; align-items: center">
        <svg class="icon svg-box" aria-hidden="true">
          <use xlink:href="#icon-a-ziyuan21"></use>
        </svg>
        <div class="text-box">
          <span>{{ $t('navigationBottom.copyright_authorization') }} </span>
          <span style="text-align: left">
            <!-- 活动申请入口 -->
            {{ $t('navigationBottom.media_Only') }}
          </span>
        </div>
        <!-- 授权申请 -->
        <authorizationApplication />
        <div @click="Jump" class="content">湘ICP备19010390号-1</div>
      </div>
      <!-- 品牌合作 -->
      <div v-if="state == 2 || state == 3" style="width: 100%; display: flex; align-items: center; justify-content: space-between">
        <div style="display: flex; align-items: center; text-align: left">
          <div class="text-box">
            <span>{{ state == 2 ? $t('navigationBottom.cooperation') : $t('navigationBottom.official_channel') }}</span>
            <span>{{
              state == 2 ? $t('navigationBottom.platform_ecological_partners') : $t('navigationBottom.discover_more_exciting_things')
            }}</span>
          </div>
          <div class="img-box">
            <img v-for="(item, index) in list" :key="index" :src="item.url" @click="imgJump(item.link)" alt="" />
          </div>
        </div>
        <!-- @click="Jump" -->
        <div class="content">湘ICP备19010390号-1</div>
        <div v-if="state == 2" class="Chat-boxs">
          <!-- 官方客服 -->
          <!-- <div class="right">
            <span style="">{{ $t('official_customer_service') }}</span>
            <Chat class="Chat" />
          </div> -->
        </div>
        <div v-if="state == 3" class="Chat-box">
          <!-- 公司导航 -->
          <div class="right" @click="onClick" style="margin-right: 80px; cursor: pointer">
            <svg class="icon company-Navigation" aria-hidden="true">
              <use xlink:href="#icon-a-Google_Maps_icon_20201"></use>
            </svg>
            <!-- 公司导航 -->
            {{ $t('navigationBottom.company_Navigation') }}
          </div>
        </div>
      </div>
      <!-- 加入我们 -->
      <div v-if="state == 5" class="joinUs">
        <div class="joinUs-box">
          <svg class="icon" aria-hidden="true" style="height: 30px; width: 30px">
            <use xlink:href="#icon-noto_calendar"></use>
          </svg>
          <div class="text-box">
            <span>{{ $t('navigationBottom.holiday_calendar') }}</span>
            <calendarBox />
          </div>
          <!-- 网红加入 -->
          <div class="internet-celebrity-joining" @click="internetCelebrityJoin" style="margin-right: 30px; cursor: pointer">
            <svg class="icon" aria-hidden="true" style="padding-top: 3px; height: 23px; width: 23px; margin-right: 8px">
              <use xlink:href="#icon-biaoshi1"></use>
            </svg>
            <!-- 网红加入 -->
            {{ $t('joinUs.Internet_celebrity_joining') }}
          </div>
        </div>
        <!-- @click="Jump" -->
        <div class="content" @click="Jump">湘ICP备19010390号-1</div>
        <div class="Chat-box-rigth"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Chat: () => import('@/components/Chat.vue'),
    authorizationApplication: () => import('./authorizationApplication.vue'),
    calendarBox: () => import('./calendarBox.vue')
  },
  data() {
    return {
      list: [], // 底部图标列表
      listA: [
        { url: require('@/assets/mxcomeImg/bottomTab/promptpay.png') },
        { url: require('@/assets/mxcomeImg/bottomTab/Group 115.png') },
        { url: require('@/assets/mxcomeImg/bottomTab/Rectangle 237.png') },
        { url: require('@/assets/mxcomeImg/bottomTab/SCB.png') },
        { url: require('@/assets/mxcomeImg/bottomTab/Group 84.png') }
      ],
      listB: [
        { url: require('@/assets/mxcomeImg/bottomTab/tiktok.png'), link: 'https://www.tiktok.com/@imxcome?_t=8YjyYTo19zW&_r=1' },
        { url: require('@/assets/mxcomeImg/bottomTab/youtube.png'), link: 'https://www.youtube.com/channel/UC6Gti3lyyqQ6XfBOCTJUzQw' },
        { url: require('@/assets/mxcomeImg/bottomTab/Group 112.png'), link: 'https://instagram.com/imxcome' }
        // { url: require('@/assets/mxcomeImg/bottomTab/Group 111.png'), link: 'https://twitter.com/imxcome2022' }
      ],
      state: 1, // 当前路由页面状态
      input: '' // 超级星期三申请输入框
    }
  },
  created() {
    console.log(this.$route.path)
    switch (this.$route.path) {
      case '/':
        this.state = 1
        break
      case '/partenship':
        this.state = 2
        this.list = this.listA
        break
      case '/aboutUs':
        this.state = 3
        this.list = this.listB
        break
      case '/newsCenter':
        this.state = 4
        this.list = []
        break
      case '/joinUs':
        this.state = 5
        this.list = []
        break
      default:
        break
    }
  },
  watch: {
    $route: {
      handler(newVal, lodValue) {
        switch (this.$route.path) {
          case '/':
            this.$store.commit('setScrollBar', true)
            this.state = 1
            break
          case '/partenship':
            this.$store.commit('setScrollBar', false)
            this.state = 2
            this.list = this.listA
            break
          case '/aboutUs':
            this.$store.commit('setScrollBar', false)
            this.state = 3
            this.list = this.listB
            break
          case '/newsCenter':
            this.$store.commit('setScrollBar', false)
            this.state = 4
            this.list = []
            break
          case '/joinUs':
            this.$store.commit('setScrollBar', false)
            this.state = 5
            this.list = []
            break
          default:
            break
        }
      },
      deep: true
    }
    // $route(to, from) {
    //   console.log(to.path)
    //   switch (to.path) {
    //     case '/':
    //       this.$store.commit('setScrollBar', true)
    //       this.state = 1
    //       break
    //     case '/partenship':
    //       this.$store.commit('setScrollBar', false)
    //       this.state = 2
    //       this.list = this.listA
    //       break
    //     case '/aboutUs':
    //       this.$store.commit('setScrollBar', false)
    //       this.state = 3
    //       this.list = this.listB
    //       break
    //     case '/newsCenter':
    //       this.$store.commit('setScrollBar', false)
    //       this.state = 4
    //       this.list = []
    //       break
    //     case '/joinUs':
    //       this.$store.commit('setScrollBar', false)
    //       this.state = 5
    //       this.list = []
    //       break
    //     default:
    //       break
    //   }
    // }
  },
  methods: {
    // 超级星期三申请
    applyFor() {
      this.$message.warning(this.$t('messageA'))
    },
    Jump() {
      window.open('https://beian.miit.gov.cn')
    },
    imgJump(val) {
      window.open(val)
    },
    // 导航按钮事件
    onClick() {
      // 30 Soi Ramintra 32 Tha Raeng Subdistrict, Bang Khen District, Bangkok 10220
      window.open(
        'https://www.google.com/maps/place/30+Soi+Rarm+Intra+32,+Khwaeng+Tha+Raeng,+Khet+Bang+Khen,+Krung+Thep+Maha+Nakhon+10220%E6%B3%B0%E5%9B%BD/@13.8501503,100.6353268,18z/data=!3m1!4b1!4m6!3m5!1s0x311d62b9554a6b21:0x4f1726776c8b03fc!8m2!3d13.8501503!4d100.6366143!16s%2Fg%2F11j1lxh35d?hl=zh-cn&entry=ttu'
      )
    },
    // 网红加入事件
    internetCelebrityJoin() {
      this.$emit('internetCelebrityJoin', true)
    }
  }
}
</script>
<style lang="less" scoped>
.svg-box {
  height: 48px;
  width: 46px;
}
.bottomNavigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 76px;
  background-color: #292929;
  z-index: 1000;
}
.home-box {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding-left: 60px;
  .text-box {
    display: flex;
    flex-direction: column;
    margin: 0 32px 0 5px;
    span {
      color: var(--white-100, #fff);
      font-size: 14px;
      font-weight: 500;
    }
    span:nth-child(2) {
      color: #51a1eb;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .input-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 291px;
    padding: 0 5px 0 15px;
    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.69);
    background: var(--black-100, #292929);
  }
  /deep/.el-input__inner {
    height: 100%;
    background-color: #292929;
    border: 0;
  }
  .content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
  }
}
.img-box {
  display: flex;
  img {
    height: 36px;
    width: 36px;
    margin-right: 27px;
  }
}
.Chat-boxs {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  min-width: 350px;
  height: 40px;
  border-radius: 24px;
  // border: .0625rem solid #f0f0f0;
  // .left {
  //   position: absolute;
  //   top: -45vh;
  //   left: -10vw;
  // }
  .right {
    min-width: 138px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    padding: 5px 16px;
  }
}
.Chat-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  min-width: 120px;
  height: 40px;
  border-radius: 24px;
  // border: .0625rem solid #f0f0f0;
  // .left {
  //   position: absolute;
  //   top: -45vh;
  //   left: -10vw;
  // }
  .right {
    min-width: 138px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #f0f0f0;
    padding: 5px 16px;
  }
}
.joinUs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .joinUs-box {
    display: flex;
    align-items: center;
    text-align: left;
  }
  .internet-celebrity-joining {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #292929;
    font-size: 14px;
    font-weight: 500;
    border-radius: 24px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
    padding: 5px 10px;
  }

  .Chat-box-rigth {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      margin-right: 12px;
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      border-radius: 24px;
      border: 1px solid #f0f0f0;
      padding: 5px 10px;
    }
  }
}
.company-Navigation {
  padding-top: 3px;
  height: 23px;
  width: 23px;
}
</style>
