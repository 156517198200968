<template>
  <div id="app" style="min-width: 1200px !important; overflow: auto">
    <!-- <div id="app" @mousewheel='scrollToSmall()'> -->
    <!-- <head-nav /> -->
    <!-- class="nav" :class="hidden ? 'hidden' : ''"  -->
    <head-nav v-if="!hidden" />
    <!-- <div class="wrapper">
      <div class="main-container">
        <div class="box">这是主要内容</div>
      </div>
      <footer>这是底部</footer>
    </div> -->
    <!-- <Chat /> -->
    <router-view />
    <BottomNavigation @internetCelebrityJoin="internetCelebrityJoin" />
    <internetCelebrityJoin :dialogVisible="internetCelebrityJoinState" @internetCelebrityJoin="internetCelebrityJoin" />
  </div>
</template>

<script>
import Chat from '@/components/Chat.vue'
import HeadNav from '@/components/HeadNav'
import BottomNavigation from '@/components/bottomNavigation.vue'
import internetCelebrityJoin from '@/views/components/joinUs/components/internetCelebrityJoin.vue'
import { mapState } from 'vuex'
export default {
  name: 'app',
  components: {
    HeadNav,
    BottomNavigation,
    internetCelebrityJoin,
    Chat
  },
  data() {
    return {
      hidden: false,
      isShowCr: true,
      urlState: true,
      Height: 0,
      internetCelebrityJoinState: false
    }
  },
  created() {
    // 文件上传url测试地址
    // this.$store.commit('setUploadUrl', 'https://app-api.mxcome.com/mall-admin/util/parseFiles')
    // 文件上传url生产地址
    this.$store.commit('setUploadUrl', 'https://gateway.mxcome.com/mall-admin/util/parseFiles')
    console.log(this.$store.state.scrollBar)
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll, true)
    this.Height = document.documentElement.scrollTop || document.body.scrollTop
  },
  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {
    Height(newVal, lodVal) {
      if (newVal > lodVal) {
        this.hidden = true
        this.isShowCr = false
      }
      if (newVal < lodVal) {
        this.hidden = false
        this.isShowCr = true
      }
    },
    scrollBar(newVal, lodVal) {
      if (newVal == true) {
        this.isShowCr = false
      } else {
        this.isShowCr = true
      }
    }
  },
  computed: {
    ...mapState(['scrollBar'])
  },
  methods: {
    handleScroll() {
      setTimeout((this.Height = document.documentElement.scrollTop || document.body.scrollTop), 3000)
    },
    // 网红加入弹框控制
    internetCelebrityJoin(val) {
      console.log(val)
      this.internetCelebrityJoinState = val
    }
  }
}
</script>

<style lang="less">
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
#app {
  overflow: hidden;
  font-family: Nunito, Avenir, Helvetica, Arial, sans-serif, Microsoft YaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  display: flex;
  /* 设置flex */
  flex-direction: column;
  /* 方向为垂直方向 */
  align-content: center;
}

nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.nav {
  opacity: 1;
  transition: opacity 1s;
}
.hidden {
  opacity: 0;
}
.crShow {
  position: fixed;
  bottom: 0;
  z-index: 99;
}
</style>
