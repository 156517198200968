import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/styles/reset.css'
import '@/assets/iconfont/iconfont.css'
import '@/assets/iconfont/iconfont.js'
Vue.config.productionTip = false
// 引入vuex-store
import store from '@/store/index.js'
import ElementUI from 'element-ui'
import * as VueGoogleMaps from 'vue2-google-maps'
import 'element-ui/lib/theme-chalk/index.css'

/**
 * @MethodAuthor: YuanZheLi
 * @Date: 2022-01-10 15:27:00
 * @Description: 统一格式化去除input首尾空格
 */
Vue.component('el-input', {
  extends: ElementUI.Input,
  created() {
    this.$on('change', (value) => {
      this.$emit('input', value.trim())
    })
  }
})
import i18n from './lang/index'
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
  load: {
    key: 'YOUR_GOOGLE_MAPS_API_KEY_GOES_HERE',
    libraries: 'places'
  }
})
import axios from 'axios'
Vue.prototype.$axios = axios
// Vue.use(VueAxios, axios);

import './utils/flexible'

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'dhAEqiSH0fdy2IZQGHdToSPnZBT22xv0'
})

import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)

import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
