export default {
  messageA: '正在开发中,敬请期待!', // 正在开发中,敬请期待期待!
  Uploading: '上传中...', // 上传中
  uploadMessage: '请上传必填项!', // 请上传必填项
  address: '曼谷市-Bang khen区-Tha Raeng街道-Soi Ramintra-32路-正6巷口 30号【邮编10220】', // 公司地址
  submitted_Successfully: '提交成功!', // 提交成功
  reproduction_of_articles: '文章转载', // 文章转载
  content_citation: '内容引用', // 内容引用
  official_customer_service: '官方客服', // 官方客服
  Re_upload: '重新上传', // 重新上传
  The_photo_format_is_incorrect: '照片格式有误', // 照片格式有误
  Photo_size_exceeds_10M: '照片大小超过10M', // 照片大小超过10M
  Please_upload_the_resume_attachment: '请上传简历附件！', // 请上传简历附件！
  menus: {
    activity: '精彩活动',
    news: '新闻中心',
    aboutUs: '关于我们',
    partenship: '平台合作',
    joinUs: '加入我们'
  },
  nav: {
    play: '越玩越赚',
    play2: '',
    login: '运营登录',
    centerTitle: '欢迎移民淘金世界',
    centerTetx: '品牌自营 共创未来'
  },
  bottom: {
    innovation: '创新',
    transform: '改变',
    future: '未来',
    Copyright: 'MXCOME © 版权所有 2023',
    Copyright_authorization_application: '版权授权申请', // 版权授权申请
    Please_select_the_authorization_type: '请选择授权类型', // 请选择授权类型
    Authorization_Type: '授权类型', // 授权类型
    Please_briefly_describe_your_needs: '请简单描述您的需求' // 请简单描述您的需求
  },
  home: {
    head: '轻松赚钱是一种生活方式',
    p2: '全新网购、社交、内容场景等你来玩！',
    p3: '激发创造力、内容变现简单高效',
    head2: '迈入品牌自营的全新时代',
    p4: '重构电商场景，品牌自营即渠道变革！',
    p5: '分享即正义，持续赋能品牌成长',
    endTime: '剩余时间',
    deadline: '截止时间',
    scan: '请扫码参与活动',
    quickly: '30S淘金达人速成',
    checkpoint: '关卡',
    countZero: '倒计时',
    day: '天',
    hours: '时',
    minute: '分',
    second: '秒',
    top1000: '前1000名淘金达人将免费获得以下排名大奖',
    search: '查询活动及中奖',
    Subscription: '淘金  营销订阅',
    emailAddress: '请输入邮箱地址',
    subscribe: '订阅',
    message: '我们将不定期发放优惠券及品牌活动通知',
    downInfo: '移动设备扫码安装',
    NoActivity: '暂无活动！',
    superWednesday: '超级星期三', // 超级星期三
    harderRigth: '进入APP探索惊喜', // 进入APP探索惊喜
    eventReservation: '活动预约', // 活动预约
    recommendationRewards: '推荐奖励', // 推荐奖励
    deal: '成交', // 成交
    one: '单', // 单
    latestEntry: '最新入驻' // 最新入驻
  },
  // 品牌合作页面
  partenship: {
    aboutSettlingIn: '关于入驻', // 关于入驻
    quickEntry: '快速入驻', // 快速入驻
    read: '5分钟阅读', // 5分钟阅读
    read2: '10分钟完成', // 10分钟阅读
    applyingForResidency: '申请入驻', // 申请入驻
    platformReview: '平台审核', // 平台审核
    activateStore: '激活店铺', // 激活店铺
    Brand_Investment_Handbook: '品牌招商手册', // 品牌招商手册
    Please_choose_the_language_that_suits_you: '请选择您适合的语言', // 请选择您适合的语言
    applyNow: '立即申请', // 立即申请
    contactInformation: '联络信息', // 联络信息
    brandMessage: '品牌信息', // 品牌信息
    fullName: '姓名', // 姓名
    contactName: '联系人姓名', // 联系人姓名
    Brand: '品牌名称', // 品牌名称
    enterContactPhoneNumber: '输入联系电话', // 输入联系电话
    brandCategories: '品牌类目', // 品牌类目
    email: 'E-mail', // E-mail
    relatedWebsites: '相关网站', // 相关网站
    addLink: '新增链接', // 新增链接
    Data: '相关资料', // 相关资料
    prompt: '如资料完全，可点击“继续认证”，平台将于48小时内完成品牌运营审核', // 如资料完全，可点击“继续认证”，平台将于48小时内完成品牌运营审核
    prompt1: '如需准备资料，可在收到密钥后登录运营后台，并在激活店铺时补充认证材料', // 如需准备资料，可在收到密钥后登陆运营后台，并在激活店铺时补充认证材料
    prompt2: '通过密钥登录品牌运营后台，点击激活店铺，完成店铺设置即可开始运营', // 通过密钥登陆品牌运营后台，点击激活店铺，完成店铺设置即可开始运营
    nextStep: '下一步', // 下一步
    pleaseSelect: '请选择', // 请选择
    brandBrochure: '品牌宣传册（PDF）', // 品牌宣传册（PDF）
    appleProductIntroduction: 'Apple产品介绍', // Apple产品介绍
    established: '已设立', // 已设立
    establishedText: '本地运营公司', // 本地运营公司
    notEstablished: '未设立', // 未设立
    prompt3: '已设立运营公司可以直接激活店铺开展运营', // 已设立运营公司可以直接激活店铺开展运营
    prompt4: '未设立运营公司可以通过平台帮助完成设立', // 未设立运营公司可以通过平台帮助完成设立
    submitForReview: '提交审核', // 提交审核
    ApplicationForEntry: '入驻申请已成功提交', // 入驻申请已成功提交
    ApplicationForEntry1: '48 小时内完成审批及密钥发放', // 48 小时内完成审批及密钥发放
    ApplicationForEntry2: '接收审核提醒', // 接收审核提醒
    ApplicationForEntry3: '接收密钥邮件', // 接收密钥邮件
    additionalInformation: '补充资料已成功提交', // 补充资料已成功提交
    prompt5: '如选择完成，可用密钥登录店铺后补充认证', // 如选择完成，可用密钥登录店铺后补充认证
    prompt6: '如资料完全，您可继续认证，节约审核时间', // 如资料完全，您可继续认证，节约审核时间
    prompt7: '请确保通讯通畅，如有必要我们将会回访', //  请确保通讯通畅，如有必要我们将会回访
    prompt8: '平台将于48小时内完成审核，请注意短信及邮件', // 平台将于48小时内完成审核，请注意短信及邮件
    prompt9: '请提供准确信息，否则将影响申请结果', // 请提供准确信息，否则将影响申请结果
    complete: '完成', // '完成'
    continuingCertification: '继续认证', // 继续认证
    Login_to_email_to_view_key: '登录邮箱查看密钥', // 登录邮箱查看密钥
    brandInformation: '品牌资料', //品牌资料
    brandSide: '品牌方', // 品牌方
    jointVenture: '合资公司', // 合资公司
    nationalAgency: '国家代理', // 国家代理
    trademarkApplicationForm: '商标申请书', // 商标申请书
    trademarkCertificate: '商标证书', // 商标证书
    certificateOfIncorporation: '(品牌)商标授权书', // (品牌)商标授权书
    registrationCertificateS: '公司登记证号', // 公司登记证号
    text2: '请根据实际情况上传相关材料，',
    text3: '您也可激活店铺后补充认证', // 请根据实际情况上传相关材料，您也可激活店铺后补充认证
    authenticationInformation: '补充认证', // 补充认证
    companyInformationS: '公司资料', // 公司资料
    directorSsignature: '董事签署', // 董事签署
    authorizedSignature: '授权签署', // 授权签署
    Example: '示例', //
    registrationCertificate: '公司登记证书', // 公司登记证书
    affidavit: '公司宣誓书', // 公司宣誓书
    effectiveDate: '有效截止日期', // '有效截止日期'
    effectiveDateS: '请选择截止日期', // '请选择截止日期'
    Enter: '请输入', // '请输入'
    taxAdministration: '税务登记证', // '税务登记证
    taxAdministrationS: '税务登记证号', // '税务登记证号'
    text4: '公司认证资料需董事或授权代表签字盖章', // '公司认证资料需董事或授权代表签字盖章'
    text6: '单张图片大小不超过3MB，支持jpg、png、pdf、bmp、jpeg、heic格式', // 单张图片大小不超过3MB，支持jpg、png、bmp格式
    text7: '单个文件不能超过10MB,支持pdf', // 单个文件不能超过10MB,支持pdf
    text8: '品牌名称不能重复，请重新输入', // 品牌名称不能重复，请重新输入
    directorEmpower: '董事授权书', // 董事授权书
    directorEmpowerTitle: '董事名字、授权代表名字', // 董事名字、授权代表名字
    continuouslyIncreasing: '持续增加中...', // 持续增加中...
    text9: '抱歉!未找到对应的邮箱登录地址，请自己登录邮箱查看邮件！', // 抱歉!未找到对应的邮箱登录地址，请自己登录邮箱查看邮件！
    text10: '公司认证资料需董事或授权代表签字盖章' // 公司认证资料需董事或授权代表签字盖章
  },
  news: {
    newsCenter: '新闻中心',
    MNews: 'MXCOME News',
    official: '直击市场动态，MXCOME官方权威发布',
    productNews: '产品消息',
    dynamic: '运营动态',
    report: '媒体报道',
    statement: '官方声明',
    search: '搜索历史新闻动态',
    readOn: '请继续阅读'
  },
  // 关于我们
  aboutUss: {
    companyProfile: '公司简介', // 公司简介
    mission: '使命愿景', // 使命愿景
    analysis: '市场分析', // 市场分析
    businessModel: '业务模型', // 业务模式
    coreAdvantages: '核心优势', // 核心优势
    coreOverview: '核心概述', // 核心优势
    Gold_Rushing_Expert: '淘金达人', // 淘金达人
    Touching_Users: '触达用户', // 触达用户
    title_text: '突破品牌销售屏障', // 突破品牌销售屏障
    title_text2: '', //
    title_text1: '帮助用户轻松赚钱', // 帮助用户轻松赚钱
    title_text3: '',
    scheduleAVisit: '预约来访', // 预约来访
    reservation: '预约', // 预约
    suggestedFeedback: '建议反馈', // 建议反馈
    contactName: '联系人姓名', // 联系人姓名
    suggestTypes: '请选择建议类型', // 请选择建议类型
    suggestTypesA: '建议类型', // 建议类型
    suggestedContent: '请输入建议内容', // 请输入建议内容
    Pictures: '上传图片', // 上传图片
    text1: '我们用心倾听', // 我们用心倾听
    text2: '发表您的想法，一但平台采纳，有机会获得最高1000金币奖励', // 发表领的想法，一但平台采纳，有机会获得最高1000金币奖励
    submitTo: '提交', // 提交
    visitingTimeA: '来访时间', // 来访时间
    visitingTime: '请选择来访时间', // 请选择来访时间
    visitingInformation: '请输入来访信息', // 请输入来访信息
    text3: '周二 三 四', // 周二 三 四
    functionalSuggestions: '功能建议', // 功能建议
    interactiveSuggestions: '交互建议', // 交互建议
    bugSubmission: 'bug提交', // bug提交
    other: '其他' // 其他
  },
  // 新闻中心
  newsCenter: {
    OfficialUpdates: '官方动态', // 官方动态
    mediaCoverage: '媒体报道', // 媒体报道
    publicWelfare: '社会公益', // 社会公益
    keywords: '输入新闻关键', // 输入新闻关键
    Popular: '热门' // 热门
  },
  // 加入我们
  joinUs: {
    experience: '经验', // 经验
    years_or_more: '年以上', // 年以上
    high_school: '高中', // 高中
    junior_college: '大专', // 大专
    undergraduate_course: '本科', // 本科
    postgraduate: '研究生', // 研究生
    Dr: '博士', // 博士
    job_search: '岗位查询', // 岗位查询
    recruitment_Department: '招聘部门', // 招聘部门
    text1: '我们知道你喜欢放假，左下角有放假通知', // 我们知道你喜欢放假，左下角有放假通知
    nature_of_Work: '工作性质', // 工作性质
    full_time: '全职', // 全职
    part_time_job: '兼职', // 兼职
    practice: '实习', // 实习
    job_Notice: '求职须知', // 求职须知
    submit_resume: '投递简历', // 投递简历
    area: '所在地区', // 所在地区
    please_select_your_region: '请选择所在地区', // 请选择所在地区
    Upload_attached_resume: '上传附件简历', // 上传附件简历
    Internet_celebrity_joining: '网红加入', // 网红加入
    screenshot_of_Today_s_Channel: '当日频道截图', // 当日频道截图
    view_examples: '查看示例', // 查看示例
    text4: '仅限10MB大小，文件格式支持（JPG PNG）', // 仅限10MB大小，文件格式支持（JPG PNG）
    text5: '我们欢迎KOL的加入，共建优质内容生态', // 我们欢迎KOL的加入，共建优质内容生态
    text6: '最低加入标准，粉丝数量需超过 10K', // 最低加入标准，粉丝数量需超过 10K
    text7: '请根据实际情况上传，并保持手机通畅', // 请根据实际情况上传，并保持手机通畅
    recruitment_policy: '招聘政策', // 招聘政策
    Onboarding_process: '入职流程', // 入职流程
    Professional_red_line: '职业红线', // 职业红线
    Not_yet_released_please_stay_tuned: '暂未发布敬请期待...', // 暂未发布敬请期待
    text8: '仅限10MB大小，文件格式支持（PDF WORD）', // 仅限10MB大小，文件格式支持（PDF WORD）
    upload_attached_resume: '上传附件简历' // 上传附件简历
  },
  // 底部导航栏
  navigationBottom: {
    event_application_portal: '活动申请入口', // 活动申请入口
    apply_for: '申请', // 申请
    cooperation: '战略合作', // 战略合作
    platform_ecological_partners: '平台生态伙伴', // 平台生态伙伴
    official_channel: '官方频道', // 官方频道
    discover_more_exciting_things: '发现更多精彩', // 发现更多精彩
    company_Navigation: '公司导航', // 公司导航
    copyright_authorization: '版权授权', // 版权授权
    media_Only: '仅限传媒', // 仅限传媒
    holiday_calendar: '放假日历', // 放假日历
    click_to_view: '点击查看', // 点击查看
    this_month_s_holiday: '本月放假日', // 本月放假日
    today: '今日', // 今日
    mark_as_vacation: '标记为放假', // 标记为放假
    some_public_holidays_have_adjustments: '部分公众假日存在调整', // 部分公众假日存在调整
    return_to_Today: '返回今日' // 返回今日
  },
  aboutUs: {
    brochure: '官方宣传册',
    Value: 'Value Co-Creation',
    Win: 'Win-Win',
    economics: '价值共创 互利共赢',
    highTech: '我们致力成为可持续发展的社会型伟大科技企业',
    web: '专注于人工智能、数字化、Web3为核心技术驱动的共享经济商业生态',
    profit: '主要利润来自平台服务及广告',
    flagship: 'MXCOME / 淘金 定位为社交推荐平台，属共享经济',
    mega: '我们希望建立轻松赚钱的生活方式，致力帮助多元化社会群体获得可持续增长的收益，同时革新现有电商销售模式',
    // mega: 'Mega content production, XEFR infinite possibility ; Come to smart Eco of TAO',
    // infinitePossibilities: '',
    infinitePossibilities: '超级内容生产，传递无限可能；进入道的智慧生态',
    zoology: '进入道的智慧生态',
    Spirit: 'The Spirit of MXCOME',
    soul: '淘金之魂',
    message: '创始寄语',
    // messageTip: `${'正义创新'&nbsp;'敬畏自然'&nbsp;'热爱生活'}`, `${&nbsp;}`
    messageTip: '正义创新 \xa0\xa0 敬畏自然 \xa0\xa0 热爱生活',
    values: '价值观',
    valuesTip: '服务全球',
    valuesTip1: '以上品牌',
    // valuesTip: '敬天爱人 \xa0\xa0 守正出奇',
    vision: '愿景',
    visionTip: '让人人都能轻松赚钱',
    // visionTip: '构建全新商业秩序',
    mission: '使命',
    missionTip: '敬天爱人，守正出奇',
    contactUs: '与我们取得联系',
    contact: '联系',
    ReceiveTime: '接待时间：9:00-18:00(周六、周日及公众假期休息)',
    Mailbox: '总务邮箱',
    harken: '我们用心倾听',
    proposal: '无论是产品、设计、还是平台规则，或是你期待的功能，发表你的想法。有效建议被采纳，您将有机会获得500-5000金币奖励',
    award: '有效建议采纳将有机会获得500-5000淘金金币奖励',
    configSuggest: '立即建议',
    office: '我们的办公室',
    address: '曼谷市-Bang khen区-Tha Raeng街道-Soi Ramintra-32路-正6巷口【邮编10220】',
    attention: '关注我们',
    moreContent: '更多精彩内容',
    userSuggest: '用户建议',
    name: '姓名',
    region: '区域',
    thai: '泰国',
    china: '中国',
    ID: 'MXCOME ID',
    phone: '联系电话',
    suggestContent: '请以文字描述建议内容',
    upload: '上传附件',
    uploadTips: '仅限10MB大小，文件格式支持（PDF WORD）',
    submit: '提交',
    GoHere: '去这里',
    copySuccess: '复制成功',
    copy_link: '复制链接' //复制链接
  },
  join: {
    joinM: 'Join MXCOME',
    welcome: 'Welcome to join the MXCOME family',
    btnThai: '泰国',
    btnCh: '中国',
    changeWork: '想换工作？MXCOME诚邀您加入。',
    joinUs: '加入我们',
    whichWork: '想换什么工作？',
    JobTitle: '请输入职位名称',
    addressDetail: '详细地址',
    workplacePlaceholder: '请选择城市办公室',
    workplace: '工作地点',
    thai: '泰国',
    china: '中国',
    usa: '美国',
    BangkokStudio: '曼谷工作室',
    ChangshaStudio: '长沙工作室',
    ShenzhenStudio: '深圳工作室',
    openPositions: '开放的岗位',
    controlCenter: 'A.管理中心',
    ProductCenter: 'T.产品中心',
    businessCenter: 'B.商业中心',
    required: '技能与资格要求',
    department: '部门',
    design: '设计',
    research: '研发', //research and development
    assetManagement: '资管',
    hr: '人资',
    generalOffice: '总办', //General Office
    planning: '企划',
    operate: '运营',
    choose: '请选择',
    type: '职位类型',
    FullTime: '全职',
    partTime: '兼职',
    practice: '实习',
    recruitmentPolicies: '招聘政策',
    procedure: '入职流程',
    redLine: '职业红线',
    markAsHoliday: '标记为放假日',
    somePublicDaysHaveAdjust: '部分公众日存在调整',
    holidayCalendar: '放假日历'
  },
  collaboration: {
    invite: 'Invite immigrants to MXCOME World',
    together: 'Strictly abide by the contract and cooperate sincerely to benefit the world and create the future together',
    multi: '平台合作',
    thai: '泰国',
    china: '中国',
    interaction: '活动合作',
    platformOnboard: '申请品牌入驻',
    events: '承办品牌专场',
    information: '投递合作资料',
    circular: '官方审核通告',
    only: '仅限10MB大小，文件格式支持（PDF WORD EXCEL)',
    search: '搜索品牌名称',
    category: '类别',

    // 平台合作
    TypeCooperation: '合作类型',
    brand: '品牌',
    CheckInTime: '入驻时间',
    OfficialAuditCircular: '官方审核通告',
    BrandSettlement: '品牌入驻',
    BrandHome: '品牌归属地',
    BrandName: '品牌名称',
    ContactName: '联系人名称',
    AreaCode: '区号',
    ContactNumber: '联系电话',
    emailBrand: '邮箱',
    webBrand: '品牌官网',
    SubmitApplication: '提交申请',
    YourApplicationPleaseWaitGoodNews: '您的申请已提交，请静候佳音',
    PleaseKeepPhoneOpen: '请保存电话畅通，如有必要我们将向您致电',
    PleaseKeepPhoneOpen2: '我们将于48小时内完成审核，并通过短信及邮件方式发送',
    know: '知道了',

    LOGO: 'LOGO',
    deliveryTime: '投递时间',
    result: '申请结果',
    remark: '备注',
    pass: '通过',
    reject: '拒绝',
    SONY: 'SONY泰国'
  }
}
