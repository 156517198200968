import request from '@/utils/request'
// 上传图片
export function policy(data) {
  return request({
    url: '/mall-admin/util/parseFiles',
    method: 'post',
    data
  })
}
// 文件上传
export function parseFiles(data) {
  return request({
    url: '/mall-portal/util/parseFiles',
    method: 'post',
    data
  })
}
// 获取活动列表
export function getActivityMemberList(data) {
  return request({
    url: '/mall-portal/activityMember/list',
    method: 'POST',
    data
  })
}
// 申请入驻
export function shopBrandApplyIntentionCreat(data) {
  return request({
    url: '/mall-admin/shopBrandApplyIntention/create',
    method: 'POST',
    data
  })
}
// 服务申请
export function shopServiceApplyCreate(data) {
  return request({
    url: '/mall-admin/shopServiceApply/create',
    method: 'POST',
    data
  })
}
// 申请入驻-列表
export function shopBrandApplyIntentionList(params) {
  return request({
    url: '/mall-admin/shopBrandApplyIntention/officialWebsiteList',
    method: 'get',
    params
  })
}
// 品牌名称去重
export function checkBrandName(params) {
  return request({
    url: '/mall-admin/shopBrandApplyIntention/checkBrandName',
    method: 'post',
    params
  })
}
// 品牌账户查询
export function checkBrandNo(params) {
  return request({
    url: '/mall-admin/shopServiceApply/checkBrandNo',
    method: 'post',
    params
  })
}
// 申请入驻-列表
export function officialWebsiteList(params) {
  return request({
    url: '/mall-admin//shopServiceApply/officialWebsiteList',
    method: 'get',
    params
  })
}
// 国家
export function listCountry(data) {
  return request({
    url: '/mall-admin/shopBrand/listCountry',
    method: 'post',
    data
  })
}

// 获取活动详情
export function getActivityMemberInfo(data) {
  return request({
    url: '/mall-portal/activityMember/getInfo',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}

// 获取新闻列表
export function getNewsList(data) {
  return request({
    url: `/mall-admin/newsMxcome/listNews?pageNum=1&pageSize=5&typeId=${data.typeId}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
// 获取新闻类型
export function getNewsType(data) {
  return request({
    url: '/mall-admin/newsMxcome/listNewsType',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}
// 根据新闻id获取辑信息
export function getNewsInfo(data) {
  return request({
    url: '/mall-admin/newsMxcome/getNewsInfo/',
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data
  })
}
// 超级星期三
export function getSuperWednesday() {
  return request({
    url: '/mall-admin/mission/getSuperWednesday',
    method: 'get'
  })
}
