import Vue from 'vue'
import VueRouter from 'vue-router'
import activityView from '@/views/activityView.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: activityView,
    meta: { title: '精彩活动' }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import(/* webpackChunkName: "aboutUs" */ '@/views/AboutView.vue')
  },
  {
    path: '/newsCenter',
    name: 'newsCenter',
    component: () => import(/* webpackChunkName: "newsCenter" */ '@/views/components/newsCenter')
  },
  {
    path: '/newsCenter/newsDetails',
    name: 'newsDetails',
    component: () => import(/* webpackChunkName: "newsDetails" */ '@/views/components/newsDetails')
  },
  {
    path: '/partenship',
    name: 'partenship',
    component: () => import(/* webpackChunkName: "partenship" */ '@/views/components/partenship')
  },
  {
    path: '/joinUs',
    name: 'joinUs',
    component: () => import(/* webpackChunkName: "joinUs" */ '@/views/components/joinUs')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  // 页面跳转后回到顶部
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
