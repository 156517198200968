<template>
  <div class="activityInfo" style="">
    <div class="left">
      <video
        id="myVideo"
        muted
        autoplay="autoplay"
        width="150px"
        loop="loop"
        webkitQ-playsinline="true"
        playslnline="true"
        x5-video-orientation="h5"
        x5-video-player-type="h5"
        x5-video-player-fullscreen=""
        x5-playsinline
        webkit-playsinline
        playsinline
        x-webkit-airplay="true"
        :src="videoUrl"
      ></video>
      <el-popover placement="top" trigger="click" popper-class="authorization-application">
        <div class="barCode" v-if="barCodeShow" style="display: flex; flex-direction: column; align-items: center">
          <vueQr v-if="barCodeShow" :pageName="pageName" :product="product" :barCodeShow="barCodeShow" />
          <!-- <img style="width: 150px" src="@/assets/mxcomeImg/Rectangle 235.png" alt="" /> -->
          <span>MXCOME SCAN </span>
        </div>
        <el-button slot="reference" @click="barCodeShow = true" class="btn" type="danger" round>{{
          $t('home.eventReservation')
        }}</el-button>
        <!-- <el-button slot="reference">click 激活</el-button> -->
      </el-popover>
      <!-- <div class="barCode" v-if="barCodeShow">
        <img src="@/assets/mxcomeImg/Rectangle 235.png" alt="" />
        <span>MXCOME SCAN</span>
      </div> -->

      <!-- 预约活动按钮 -->
      <!-- <el-button @click="barCodeShow = true" class="btn" type="danger" round>{{ $t('home.eventReservation') }}</el-button> -->
    </div>
    <div class="rigth">
      <!-- 头部 -->
      <div class="title">
        <span>
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-a-Group22"></use>
          </svg>
          <!-- 超级星期三 -->
          <span>{{ $t('home.superWednesday') }}</span>
        </span>
        <!-- 进入APP探索惊喜 -->
        <span class="text">{{ $t('home.harderRigth') }}</span>
      </div>
      <!-- 活动table -->
      <tabList @onClick="onClick" :clickState="clickState" :listData="item" :index="index" v-for="(item, index) in list" :key="index" />
      <!-- <div
        @click="onClick(item, index)"
        v-for="(item, index) in list"
        :key="index"
        :class="[clickState == index ? 'selectedTable' : '', 'table']"
      >
        <div class="title">
          <img :src="item.shopLogo" alt="" />
          <span>{{ item.shopName }}</span>
        </div> -->
      <!-- 内容部分 -->
      <!-- <div class="text-box">
          <div>
            <img :src="item.pocketProductPic" alt="" />
          </div>
          <div class="content-box">
            <span class="title-text">{{ item.pocketProductName }}</span>
            <div class="content-text">
              <span>
                ฿{{ item.price }}
                <span class="text">฿{{ item.guidingPrice }}</span>
              </span>
              <div class="icon-text">{{ handleDivideMoist(item.ratio) }}%</div>
            </div>
            <span class="price-box">
              <svg class="icon" aria-hidden="true" style="padding-top: 3px; height: 15px; width: 15px">
                <use xlink:href="#icon-a-zu22146"></use>
              </svg>
              <span class="text">฿{{ item.memberProfitAmount }}/件</span>
            </span>
          </div>
        </div> -->
      <!-- 底部部分 -->
      <!-- <div class="bottom-box">
          <div class="bottom-box-left">
            <svg class="icon" aria-hidden="true" style="padding-top: 3px; height: 23px; width: 23px">
              <use xlink:href="#icon-time_icon"></use>
            </svg>
            <span>{{ item.date }}</span>
          </div>
          <div class="bottom-box-right">
            <svg class="icon" aria-hidden="true" style="padding-top: 3px; height: 23px; width: 23px">
              <use xlink:href="#icon-gift_icon"></use>
            </svg>
            <p>
              {{ $t('home.recommendationRewards') }}
              <span style="color: #b2b2b2">
                {{ $t('home.deal') }}
                <span style="color: #f45656">
                  {{ item.num }}
                </span>
                {{ $t('home.one') }}
              </span>
            </p>
          </div>
        </div> 
      </div>-->
    </div>
  </div>
</template>
<script>
import vueQr from '@/components/vueQr.vue'
import tabList from './components/tabList.vue'
import { getSuperWednesday } from '@/api/index.js'
export default {
  components: {
    vueQr,
    tabList
    // consociation
  },
  data() {
    return {
      barCodeShow: false, // 活动 预约条码
      pageName: 'ProductDetail', // 跳转app页面
      product: '', // 产品id
      list: [],
      clickState: 0,
      videoUrl: ''
    }
  },
  created() {
    this.getInfo()
  },
  watch: {
    list: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal)
      },
      deep: true // 加这个属性，深度监听
    }
  },
  methods: {
    // 活动预约
    eventAppointment() {},
    // 获取超级星期三
    getInfo() {
      getSuperWednesday().then((res) => {
        if (res.code == 200) {
          this.list = res.data
          if (res.data.length == 0) {
            this.$store.commit('setScrollBar', true)
            console.log(this.$store.state.scrollBar)
            this.$emit('onShow', false)
            return
          }
          this.$emit('onShow', true)
          this.list.forEach((item, index) => {
            // setInterval(() => {
            this.getTime(this.list, index)
            // }, 1000)
          })
          this.product = res.data.length > 0 ? res.data[0].productId : ''
          this.videoUrl = this.list.length > 0 ? this.list[0].pocketVideo : ''
          // this.getTime(this.list[0].pocketEndTime)
        }
      })
    },
    handleDivideMoist(val) {
      let data = val * 100

      return data.toFixed(2)
    },
    // 计算时间差
    getTime(val, i) {
      console.log(val)
      // let datas = setInterval(() => {
      this.list[i].endTimeSecond = this.list[i].endTimeSecond - 1
      let value = this.list[i].endTimeSecond

      let days = parseInt(value / 86400) // 天  24*60*60*1000
      let hours = parseInt(value / 3600) - 24 * days // 小时 60*60 总小时数-过去的小时数=现在的小时数
      let minutes = parseInt((value % 3600) / 60) // 分钟 -(day*24) 以60秒为一整份 取余 剩下秒数 秒数/60 就是分钟数
      let seconds = parseInt(value % 60) // 以60秒为一整份 取余 剩下秒数
      console.log(days + '天' + hours + ':' + minutes + ':' + seconds)
      this.list[i].date = days + this.$t('home.day') + hours + ':' + minutes + ':' + seconds
      // setInterval(() => {
      // this.getTime(this.list, i)
      // }, 1000)
    },
    // 活动点击事件
    onClick(val, index) {
      this.product = val.productId
      this.videoUrl = val.pocketVideo
      document.getElementById('myVideo').load()
      this.clickState = index
    }
  }
}
</script>
<style lang="less">
.authorization-application {
  margin-bottom: 50px !important;
}
.activityInfo {
  height: 100%;
  width: 100%;
  display: flex;
  .left {
    position: relative;
    width: 30%;
    height: 100%;
    video {
      // height: 100%;
      width: 100%;
    }
    .btn {
      width: 238px;
      position: absolute;
      bottom: 100px;
      left: 50%;
      transform: translateX(-50%);
      // font-size: 1.25rem;
      font-weight: 600;
    }
    .barCode {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
      padding: 27px 39px;
      border-radius: 10px;
      background-color: #fff;
      img {
        height: 242px;
        width: 242px;
      }
      span {
        margin-top: 17px;
        color: #000;
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
  .rigth {
    width: 70%;
    height: 100%;
    padding: 46px 66px 0;
    padding-bottom: 30px;
    overflow-y: scroll;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span:nth-child(1) {
        color: var(--black-100, #292929);
        font-family: SF Pro Display;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
      }
      .text {
        color: var(--black-100, #292929);
        font-family: SF Pro Display;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .selectedTable {
    border: 1px solid #f45656;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12) !important;
  }
  .table {
    margin-top: 30px;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    .title {
      display: flex;
      justify-content: left;
      padding: 6px 15px;
      background: var(--black-f-5, #f5f5f5);
      img {
        width: 32px;
        height: 32px;
      }
      span {
        margin-left: 10px;
        color: var(--black-100, #292929);
        font-size: 20px;
        font-weight: 600;
      }
    }
    .text-box {
      padding-left: 20px;
      display: flex;
      img {
        height: 120px;
        width: 120px;
        border-radius: 6px;
        overflow: hidden;
      }
      .content-box {
        width: 100%;
        display: flex;
        padding-left: 20px;
        flex-direction: column;
        text-align: left;
        .title-text {
          color: var(--black-100, #292929);
          font-size: 18px;
          font-weight: 590;
        }
        .content-text {
          display: flex;
          justify-content: space-between;
          color: var(--black-100, #292929);
          font-size: 16px;
          font-weight: 510;
          margin: 21px 0;
          .text {
            color: #b2b2b2;
            font-size: 14px;
            text-decoration: line-through;
            font-weight: 400;
            margin-left: 20px;
          }
          .icon-text {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--white-100, #fff);
            font-size: 14px;
            font-weight: 700;
            height: 24px;
            width: 56px;
            background: url('@/assets/mxcomeImg/activity/22506.png') no-repeat;
            background-size: 100% 100%;
            margin-right: 20px;
            min-width: 70px;
            padding-left: 11px;
          }
        }
        .price-box {
          width: 114px;
          padding: 4px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 19px;
          background: var(--mxfef-5, #fef5f5);
          .text {
            font-size: 12px;
            color: var(--mx-100, #f45656);
            font-weight: 590;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .bottom-box {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-top: 1px solid #f5f5f5;
    margin-top: 11px;
    .bottom-box-left {
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        font-weight: 500;
        color: #f45656;
        margin-left: 7px;
      }
    }
    .bottom-box-right {
      display: flex;
      align-items: center;
      p {
        margin-left: 7px;
        font-size: 16px;
        color: #292929;
        font-weight: 400;
      }
    }
  }
}
</style>
